import { AppTracker } from "@~/common/components/trackers"
import { decodeGAClientID } from "lib/auth/multidevice"
import React from "react"

export const MainAppTracker = () => {
  return <AppTracker getGTMInitialVars={initialGTMTag} />
}

/** GTMに渡す初期データを返す */
const initialGTMTag = () => {
  const c = decodeGAClientID()
  return c ? { clientId: c } : undefined
}
