import { extendTheme } from "@chakra-ui/react"
import { theme as baseTheme } from "@~/common/components/system/theme"
import { Roboto } from "next/font/google"

const roboto = Roboto({
  weight: "400",
  subsets: ["latin"],
})

/** apps/main での共通テーマ */
export const theme = extendTheme(baseTheme, {
  config: {
    cssVarPrefix: "o",
    initialColorMode: "light",
    useSystemColorMode: false,
  },
  fonts: {
    roboto: roboto.style.fontFamily,
  },
  styles: {
    global: {
      /** ページ内リンクのヘッダー分だけ、スクロール位置を調整する */
      html: {
        scrollPaddingTop: {
          base: "80px",
          md: "30px",
        },
      },
    },
  },
})

export type Theme = typeof theme
