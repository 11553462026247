import { GTMDataLayer } from "./tracker-globals"

/** GTMのデータレイヤーに値を追加します */
export const AppGTMDatalayerPush = (v: any) => {
  if (process.env.NODE_ENV === "development" && typeof window === "object") {
    console.groupCollapsed("[dataLayer] new event")
    console.log(v)
    console.groupEnd()
  }
  return datalayer().push(v)
}

export const AppGTMPageView = (url: string, title: string) => {
  const payload = {
    event: "routeChangeComplete",
    pagePath: url,
    pageTitle: title,
  }
  AppGTMDatalayerPush(payload)
}

const datalayer = (): GTMDataLayer => {
  // クライアントサイドではdataLayerを初期化するか取得して、サーバサイドはダミーの配列を返す
  return typeof window !== "undefined" ? (window.dataLayer ||= []) : []
}
