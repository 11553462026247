import { loadSourcePage } from "@~/common/lib/track"
import { parseURLParams } from "./source_tracking"

export const loadAdParams = (
  rawSource = loadSourcePage()
): { [key: string]: string | undefined } => {
  if (!rawSource) return {}

  const searchParams = parseURLParams(rawSource)
  return {
    gclid: searchParams.get("gclid") || undefined,
    fbclid: searchParams.get("fbclid") || undefined,
    yclid: searchParams.get("yclid") || undefined,
  }
}
