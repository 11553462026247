import { ComponentStyleConfig } from "@chakra-ui/react"
import { transparentize } from "@chakra-ui/theme-tools"

const uiMainBG = transparentize("ui.main", 0.2)

const defaultProps = {
  variant: "form",
}

export default {
  variants: {
    form: {
      control: {
        borderRadius: "base",
        borderColor: "ui.gray2",
        _checked: {
          bg: uiMainBG,
          borderColor: "transparent",
          color: "ui.main",
        },
      },
    },
    confirm: {
      control: {
        boxSize: "17px",
        border: "1.5px solid",
        borderRadius: "0",
        borderColor: "#000",
        _checked: {
          bg: "moneiro-color-white",
          borderColor: "#000",
          color: "moneiro-color-main-orange",
          _hover: {
            bg: "moneiro-color-white",
            borderColor: "#000",
            color: "moneiro-color-main-orange",
          },
        },
      },
      label: {
        fontSize: "12px",
      },
    },
  },
  defaultProps,
} as ComponentStyleConfig
