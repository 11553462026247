import { ComponentStyleConfig } from "@chakra-ui/react"

function variantEnclosed(props: Record<string, any>) {
  if (props.colorScheme !== "moneiro") return

  return {
    tab: {
      bgColor: "ui.secondary",
      color: "#fff",
      fontWeight: "bold",
      opacity: 0.7,
      height: "32px",
      borderTopRadius: "xl",
      border: "none",
      _selected: {
        opacity: 1,
        height: "40px",
      },
    },
    tablist: {
      alignItems: "flex-end",
      borderBottom: !props.isFitted ? "3px solid" : "none",
      borderColor: "ui.secondary",
    },
    tabpanel: {
      bgColor: "ui.gray",
      padding: "30px 15px",
      borderBottomRadius: !props.isFitted ? "none" : "md",
    },
  }
}

const variants = {
  enclosed: variantEnclosed,
}

const defaultProps = {
  colorScheme: "moneiro",
}

export default { variants, defaultProps } as ComponentStyleConfig
