import React, { useEffect } from "react"
import Head from "next/head"
import Router from "next/router"

function redirectTo(destination: any, { res, status }: any = {}) {
  if (res) {
    res.writeHead(status || 302, { Location: destination })
    res.end()
  } else {
    if (destination[0] === "/" && destination[1] !== "/") {
      Router.push(destination)
    } else {
      window.location = destination
    }
  }
}

export const RedirectTo = ({
  destination,
  method = "push",
}: {
  destination: any
  method?: "push" | "replace"
}) => {
  useEffect(() => {
    if (destination[0] === "/" && destination[1] !== "/") {
      Router[method](destination)
    } else {
      window.location = destination
    }
  }, [destination])
  return null
}

export const MetaRedirect = (props: {
  after?: number
  destination: string
}) => {
  return (
    <Head>
      <meta
        httpEquiv="refresh"
        content={`${props.after ?? 0}; url=${props.destination}`}
      />
    </Head>
  )
}

export const redirect = (destination: string): React.ComponentType<any> =>
  class RedirectRoute extends React.Component<any> {
    static getInitialProps({ res }) {
      if (typeof window === "undefined" && !res.writeHead) {
        // This is the SSR mode
        return { metaRedirect: true }
      }

      redirectTo(destination, { res, status: 302 })
      return {}
    }

    render() {
      if (this.props.metaRedirect) {
        return <MetaRedirect destination={destination} />
      }

      return null
    }
  }
