import { useUnmountEffect } from "@chakra-ui/react"
import { RedirectTo } from "@~/common/components/system/Redirect"
import { LiteralUnion } from "@~/common/lib/typeutil"
import React, { useCallback, useEffect } from "react"
import {
  AuthLoginModalProps,
  useAuthState,
  useLoginModalMethod,
  useLoginModalProps,
} from "./AuthContext"
import { useAuthProviderConfig } from "./AuthProviderConfig"
import { LoginModalOption } from "./firebase/ui"

/** ログインが必要なページではこれで囲む
 *
 * ログインが必要になった時点でprops.Unauthenticatedが表示されます。
 * 未チェック時とログイン時にはprops.childrenが表示されます。
 */
export const AuthenticatedContainer = ({
  children,
  preferUI,
  unauthenticated: Unauthenticated = DefaultUnauthenticatedPresenter,
  _loginUI,
  fallback = "modal",
  authenticatedCriteria = "customer",
}: AuthenticatedContainerProps) => {
  const provider = useAuthProviderConfig()
  const { authChecked, authUser, authInternal } = useAuthState()
  const loggedin =
    authenticatedCriteria === "customer" ? authInternal : authUser

  const _props = useLoginModalProps()
  const { onOpen, onClose } = useLoginModalMethod()

  // モーダルの場合は開く
  // 開く場合は開く前にpropsを調整する
  useEffect(() => {
    if (fallback !== "modal" || !authChecked || loggedin) return
    if (_props.current) {
      // 外をクリックしても閉じられないようにする
      _props.current.modal.closeOnEsc = false
      _props.current.modal.closeOnOverlayClick = false
      _props.current.option.next_path = undefined
      _props.current.option.preferUI = preferUI
      _props.current.option.backTo = undefined
      _props.current.option._loginUI = _loginUI
    }
    onOpen()
    return () => {
      onClose()
    }
  }, [
    loggedin,
    fallback,
    preferUI,
    _loginUI,
    authChecked,
    onOpen,
    onClose,
    _props,
  ])

  // モーダルの場合を除き、未チェック時はとりあえず何も表示しない
  if (fallback !== "modal" && !authChecked) {
    return <></>
  }

  if (!loggedin) {
    if (fallback === "modal") {
      // fallthrough
    } else if (fallback === "component") {
      return <Unauthenticated />
    } else {
      if (!provider.sessionStartPath) {
        return <Unauthenticated />
      }
      return (
        <RedirectTo
          destination={`${provider.sessionStartPath}?next=${encodeURIComponent(
            fallback
          )}`}
        />
      )
    }
  }

  return <>{children}</>
}

export type AuthenticatedContainerProps = {
  /** 認証されていない場合の動作
   *
   * @default "modal"
   * - "component": unauthenticatedコンポーネントを表示します。
   * - "modal": ページを表示したままログインモーダルを表示します。
   * - string: このURLをnext_pathに指定してログインフォームにリダイレクトします。URLエンコードする必要はありません。
   */
  fallback?: LiteralUnion<"component" | "modal", string>
  /** 未ログインの時に表示されるコンポーネント
   *
   * @default `DefaultUnauthenticatedPresenter`
   *
   * モーダルにするとかはまだ考えていません。
   */
  unauthenticated?: React.ComponentType<UnauthenticatedPresenterProps>

  /** 認証済みとする基準
   *
   * @default "customer"
   *
   * - "customer": 登録済みのユーザー
   * - "includeAnon": 匿名ユーザーを含む
   */
  authenticatedCriteria?: "customer" | "includeAnon"

  /** 優先して表示するUI
   * @default "auto"
   *
   * - "signup" : 新規登録画面
   * - "login" : ログイン画面
   * - "auto": 基本はsignupだけど、URLにlogin_hintがあればloginにする
   */
  preferUI?: LoginModalOption["preferUI"]

  /** LoginUIに渡すprops
   * `variant`を渡すのに使用します
   */
  _loginUI?: LoginModalOption["_loginUI"]

  /** 未ログイン時とログイン後に表示されるコンポーネント */
  children?: React.ReactNode
}

const DefaultUnauthenticatedPresenter = (
  props: UnauthenticatedPresenterProps
) => {
  return <>このページを見るにはログインしてください!</>
}

type UnauthenticatedPresenterProps = {}

export const useLoginModal = (options?: Partial<AuthLoginModalProps>) => {
  const { onOpen, onClose } = useLoginModalMethod()
  const _props = useLoginModalProps()

  const openSignupModal = useCallback(() => {
    const props = _props.current
    if (props) {
      props.modal.closeOnEsc = options?.modal?.closeOnEsc ?? true
      props.modal.closeOnOverlayClick =
        options?.modal?.closeOnOverlayClick ?? true
      props.option.preferUI = "signup"
      props.option.next_path = options?.option?.next_path
      props.option.backTo = "close"
      props.option._loginUI = options?.option?._loginUI
    }
    onOpen()
  }, [options, _props, onOpen])

  const openLoginModal = useCallback(() => {
    const props = _props.current
    if (props) {
      props.modal.closeOnEsc = options?.modal?.closeOnEsc ?? true
      props.modal.closeOnOverlayClick =
        options?.modal?.closeOnOverlayClick ?? true
      props.option.preferUI = "login"
      props.option.next_path = options?.option?.next_path
      props.option.backTo = "close"
      props.option._loginUI = options?.option?._loginUI
    }
    onOpen()
  }, [options, _props, onOpen])

  useUnmountEffect(() => {
    // LoginUIでボタンを押してページ遷移したら閉じる
    onClose()
  }, [onClose])

  return [openLoginModal, openSignupModal] as [
    login: () => unknown,
    signup: () => unknown
  ]
}
