import React from "react"
import Head from "next/head"

export const CommonMetaTag = () => (
  <Head>
    {
      // Production以外はデフォルトでnoindexを指定する
      // ドメインを割り当てるとPreviewでもx-robots-tagヘッダーが入らないため
      process.env.VERCEL_ENV !== "production" && (
        <meta key="robots" name="robots" content="noindex" />
      )
    }
    <meta
      key="viewport"
      name="viewport"
      content="width=device-width, initial-scale=1, user-scalable=yes, maximum-scale=1"
    />
    <meta key="theme-color" name="theme-color" content="#f5f3f2" />
    {preconnectUrls.map((u) => (
      <link key={u} rel="preconnect" href={u} crossOrigin="anonymous" />
    ))}

    <meta key="fb:app_id" property="fb:app_id" content="959392484433694" />
    <meta key="og:locale" property="og:locale" content="ja_JP" />
    <meta
      key="og:site_name"
      property="og:site_name"
      content="資産運用はじめるならマネイロ"
    />
    <meta key="og:type" property="og:type" content="website" />
    <meta key="author" name="author" content="モニクルフィナンシャル" />
    <meta
      key="twitter:card"
      name="twitter:card"
      content="summary_large_image"
    />
    <meta
      key="apple-mobile-web-app-title"
      name="apple-mobile-web-app-title"
      content="マネイロ"
    />
    <link rel="icon" href="/favicon.ico" type="image/x-icon" />
    <link rel="icon" href="/favicon.svg" type="image/svg+xml" />
  </Head>
)

const preconnectUrls = [
  "https://fonts.gstatic.com",
  "https://www.googletagmanager.com",
  "https://www.google-analytics.com",
  "https://www.googletagservices.com",
]
