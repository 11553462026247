import { HubSpotTracker } from "./tracker-globals"

export const getHubSpot = (): HubSpotTracker => {
  return typeof window !== "undefined" ? (window._hsq ||= []) : []
}

export const withHubSpot = (f: (hsp: HubSpotTracker["push"]) => unknown) => {
  const hs = getHubSpot()
  let push: HubSpotTracker["push"] = hs.push.bind(hs)

  if (process.env.NODE_ENV === "development" && typeof window === "object") {
    push = (...args: any) => {
      console.groupCollapsed("[hubSpot] new event")
      console.log(args[0])
      console.groupEnd()
      hs.push.call(hs, ...args)
    }
  }
  return f(push)
}
