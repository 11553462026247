import { mode } from "@chakra-ui/theme-tools"
import {
  ComponentStyleConfig,
  ComponentSingleStyleConfig,
} from "@chakra-ui/react"

const baseStyle: ComponentSingleStyleConfig["baseStyle"] = (
  props: Record<string, any>
) => {
  const start = mode("gray.100", "gray.800")(props)

  // アニメーションさせない
  return {
    animation: "none",
    opacity: 0.7,
    borderRadius: "2px",
    borderColor: start,
    background: start,
  }
}

export default { baseStyle } as ComponentStyleConfig
