export const PublicBaseURL = "https://moneiro.jp"

/** カンパニーサイトのURL等 */
export const StaticRoutes = {
  /** トップページ */
  Root: "https://moniclefinancial.co.jp",
  /** 金融商品取引法に基づく表示 */
  Financial: `${PublicBaseURL}/financial`,
  /** 個人情報保護方針 */
  Privacy: `${PublicBaseURL}/privacy`,
  /** 勧誘方針 */
  Solicitation: `${PublicBaseURL}/solicitation`,
  /** 利用規約 */
  Rule: `${PublicBaseURL}/rule`,

  News: `https://moniclefinancial.co.jp/news`,
  Contact: `https://moniclefinancial.co.jp/contact`,
  Recruit: `https://moniclefinancial.co.jp/recruit`,

  HowToZoom: `${PublicBaseURL}/how-to-meeting`,

  services: {
    /** サービスサイトトップページ */
    Root: PublicBaseURL,
    /** オンラインセミナー */
    SeminarOnline: PublicBaseURL + "/feature/money-seminar",
    /** マイページ */
    MyPage: PublicBaseURL + "/mypage",
    /** Zoom使用方法解説ページ */
    Zoom: PublicBaseURL + "/how-to-meeting",
  },

  social: {
    Instagram: "https://www.instagram.com/moneiro_money/",
    Line: "https://line.me/R/ti/p/@549krhbb",
  },

  lp: {
    Consulting: PublicBaseURL + "/consulting",
    Woman3: PublicBaseURL + "/feature/woman-3",
    TsumitateNisa100: PublicBaseURL + "/feature/tsumitate-nisa-100",
    Smart: PublicBaseURL + "/feature/smart",
    Edu: PublicBaseURL + "/feature/consulting-edu",
  },

  /** マネイロメディア */
  media: {
    Root: PublicBaseURL + "/media",
  },

  /** モゲレコ公式サイト */
  Mogereco: "https://s.mogecheck.jp/purchase-maneiro",
  /** モゲチェック公式サイト */
  Mogecheck: "https://mogecheck.jp/",
} as const

export type Services = "SeminarOnline" | "Concier"

export const PreSeminarFallbackHLSURL =
  "https://player.vimeo.com/external/861888616.m3u8?s=8c56c7850cb4ee58351e4df4cf054ac720c803c3&logging=false"

/** ページタイトルのSuffix */
export const TitleSuffixes = {
  simple: "｜マネイロ",
  full: "｜資産運用のマネイロ",
  lp: "｜マネイロの無料オンラインセミナー",
}

/** 2Bセミナー用のmedia=パラメーターの値 */
export const MediaValue2B = "2b"

/** 面談の時間（秒） */
export const ConsultingSeconds = {
  online: 3600,
  offline: 5400,
}
