import type * as Sentry from "@sentry/nextjs"
import { User as firebaseUser } from "firebase/auth"
import { AppGTMSetUserID, AppHubSpotIdentity } from "lib/track"
import { useEffect } from "react"
import { useAuthState } from "../auth"

/** ユーザーID等の情報をGTMやSentryに紐付ける */
export const UserStateTracker = () => {
  const auth = useAuthState()
  useEffect(() => {
    if ("rawValue" in auth) {
      const fbuser = auth.rawValue as firebaseUser | null
      if (fbuser) {
        void setSentryUser(fbuser)
        AppGTMSetUserID(fbuser.uid, fbuser.email)
        AppHubSpotIdentity(
          fbuser.emailVerified ? fbuser.email : null,
          fbuser.uid
        )
      }
    }
  }, [auth.type])
  return null
}

const setSentryUser = async (fb: firebaseUser | null) => {
  if (!fb) return
  let user: Sentry.User = {}
  user.id = fb.uid
  user.email = fb.email ?? undefined
  user.username = fb.displayName ?? undefined
  const Sentry = await import("@sentry/nextjs")
  Sentry.setUser(user)
}
