import type { BrowserOptions } from "@sentry/nextjs"
import { isFirebaseError, isInternalAuthError } from "./auth/error"
import { isOIDCAuthError } from "./oidc/error"
import type { AxiosError } from "axios"

function isAxiosError(payload: unknown): payload is AxiosError {
  return (
    typeof payload === "object" &&
    !!payload &&
    "isAxiosError" in payload &&
    payload.isAxiosError === true
  )
}

export const beforeSend: BrowserOptions["beforeSend"] = (event, hint) => {
  const err = hint.originalException
  if (err instanceof Error) {
    // まとまってしまうエラーは {{default}} を含んで分割
    // バラけるエラーは {{default}} を含まないで分割
    if (isAxiosError(err)) {
      const fullURL = String(
        [err.config.baseURL, err.config.url].filter(Boolean).join("")
      )
      event.fingerprint = [
        "{{ default }}",
        fullURL,
        String(err.response?.status),
      ]
      event.message = `${err.message}: ${fullURL}`
    } else if (isInternalAuthError(err)) {
      event.fingerprint = ["internal-auth-error", String(err.code)]
    } else if (isFirebaseError(err)) {
      event.fingerprint = ["firebase-error", String(err.code)]
    } else if (isOIDCAuthError(err)) {
      event.fingerprint = ["oidc-auth-error", String(err.code)]
    }
  }
  return event
}
