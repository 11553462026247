var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"623f497810d6a2116bc7547c913208c1b689235c"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs"
import { BrowserOptions } from "@sentry/nextjs"
import { beforeSend } from "lib/sentry.config"

// https://gist.github.com/impressiver/5092952
const recommendedConfig: Partial<BrowserOptions> = {
  ignoreErrors: [
    // TODO: サーバサイドのエラーを削除
    // ネットワークエラー
    "TypeError: Failed to fetch",
    "NetworkError",
    "Unexpected end of input",
    "read ECONNRESET",
    "Load failed",

    // 中断
    "TypeError: キャンセルしました",
    "TypeError: Cancelled",
    "AbortError",
    "Bad Gateway",

    // ブラウザの拡張など
    "instantSearchSDKJSBridgeClearHighlight",
    "removeAllHighlightsForSB",
    "Fuji is not defined",
    "Can't find variable: YAHOO",
    "_AutofillCallbackHandler",

    // Metaのブラウザ(Instagram, Facebook)
    "PaymentAutofillConfig",

    // macOS Safari(16.4)
    'r["@context"].toLowerCase',

    // クライアントサイドのネットワークの問題（の可能性は高そう）、もしくはデプロイ中の問題か
    // https://stackoverflow.com/questions/69047420/webpack-code-splitting-chunkloaderror-loading-chunk-x-failed-but-the-chunk-e
    "ChunkLoadError",

    // Firebaseの内部エラー
    /^L$/,

    // ハイドレーションエラー
    // 環境依存で再現しないものも多いので通知しない
    "Hydration failed because the initial UI does not match what was rendered on the server.",
    "Text content does not match server-rendered HTML.",
    "There was an error while hydrating. Because the error happened outside of a Suspense boundary, the entire root will switch to client rendering.",

    // Next.js
    "Cancel rendering route",

    // https://github.com/vercel/next.js/issues/43088
    /attempted to hard navigate to the same URL/,
  ],
  // 例外を発生させる許容ドメインリスト
  allowUrls: [/https?:\/\/moneiro\.jp/, /anonymous/],
  // NOTE: allowUrls指定に伴い一旦無効化
  // denyUrls: [
  //   // PlyrがいつもエラーにするVimeoのエラーを無視
  //   /vimeo\.com\/api\/v2\/video\//i,
  //   // Sentry: STOREFRONT-TG
  //   // GTM: https://tagmanager.google.com/#/container/accounts/4702296285/containers/34682758/workspaces/53/triggers
  //   /cdn-f\.adsmoloco\.com\//i,
  //   // Outbrain
  //   /amplify\.outbrain\.com\//i,
  //   // Clarity
  //   /www\.clarity\.ms\//i,
  // ],
}

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
const SENTRY_ENVIRONMENT =
  process.env.VERCEL_ENV || process.env.NEXT_PUBLIC_VERCEL_ENV || "development"
Sentry.init({
  environment: SENTRY_ENVIRONMENT,
  dsn: SENTRY_DSN,
  tracesSampleRate: 0.1,
  beforeSend,
  ...recommendedConfig,
})
