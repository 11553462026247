import generated from "./colors-figma.gen"

export const colors: any = {
  ...generated,
  ui: {
    general: "#FF5500",
    main: "#F75E29",
    sub: "#FF9F35",
    secondary: "#141F84",
    // moneiro-color/Fill-Black
    // moneiro-color/Main Black
    black: "#13161C",
    gray: "#D0D2D9",
    gray2: "#DDDDDD",
  },
  text: {
    trueBlack: "#000000",
    primary: "#212121",
    black: "#222222",
    secondary: "#141F84",
    gray: "#ABABAB",
    gray2: "#9A9A9A",
    gray3: "#828282",
    gray4: "#999999",
    gray5: "#808080",
    darkDisabled: "#424549",
    main: "#F75E29", // figma: Main Orange
    mainDark: "#E54D0B", // figma: Sub Orange
    required: "#FF1E00",
    mechoiceBlack: "#272828",
  },
  line: {
    gray: "#E7E7E7",
    gray2: "#CCCCCC",
    gray3: "#BCBCBC", // #222222 opacity 0.3
    black: "#222222",
    lightBlack: "#212121", // rgba(0,0,0,0.87) on white
  },
  bg: {
    gray: "#E9E9E9",
    gray2: "#F4F4F4", // figma: Pale Gray
    gray3: "#404040",
    gray4: "#F1F1F0",
    gray5: "#FAFAFA",
    disabled: "rgba(0, 0, 0, 0.1)",
    // Figmaでは rgba(255, 159, 53, 0.15) だが透過したい訳ではないので
    sub: "#fff0e0",
    sub2: "#fff1e1",
  },
  form: {},
  // TODO: 以下はデフォルトのテーマで定義されているが、使用していないなら削除したい
  // facebook: "",
  // messenger: "",
  // linkedin: "",
  // whatsapp: "",
  // twitter: "",
  // telegram: "",
}
