import { transparentize } from "@chakra-ui/theme-tools"
import {
  ComponentStyleConfig,
  ComponentSingleStyleConfig,
} from "@chakra-ui/react"

const baseStyle: ComponentSingleStyleConfig["baseStyle"] = (
  props: Record<string, any>
) => {
  if (props.colorScheme !== "form") return { track: {}, filledTrack: {} }

  return {
    filledTrack: {
      transition: "all 0.3s",
      bgColor: "ui.main",
    },
    track: {
      bgColor: transparentize("ui.main", 0.14),
    },
  }
}
const defaultProps = {
  colorScheme: "form",
}

export default { baseStyle, defaultProps } as ComponentStyleConfig
