import { httpFetch } from "@~/common/lib/fetch"

export const getFormData = async (key: string) => {
  const response = await httpFetch(`/api/consulting/kv?key=${key}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
  return (await response.json()) as Promise<JsonValue>
}

export const send = async <T>(key: string, data: T, ttl: number) => {
  const response = await httpFetch("/api/consulting/kv", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      key,
      data,
      ttl,
    }),
  })
  return await response.json()
}
