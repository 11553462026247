import { useRouter } from "next/router"
import { useEffect } from "react"

function onConversationsAPIReady() {
  console.log(`HubSpot Conversations API: ${window.HubSpotConversations}`)
}

// NOTE: チャットボットを表示するページのパス
const TARGET_PAGE_PATH_LIST = ["/"]

/** HubSpot Chat */
/** docs @see https://developers.hubspot.jp/beta-docs/reference/api/conversations/chat-widget-sdk */
export const HubSpotChat = () => {
  const router = useRouter()

  useEffect(() => {
    if (!TARGET_PAGE_PATH_LIST.includes(router.pathname)) {
      return
    }

    /*
      If external API methods are already available, use them.
    */
    if (window.HubSpotConversations) {
      onConversationsAPIReady()
      window.HubSpotConversations.widget.refresh({
        openToNewThread: false,
      })
    } else {
      /*
        Otherwise, callbacks can be added to the hsConversationsOnReady on the window object.
        These callbacks will be called once the external API has been initialized.
      */
      window
      window.hsConversationsOnReady = [onConversationsAPIReady]
    }
    return () => {
      window.HubSpotConversations &&
        window.HubSpotConversations.widget &&
        window.HubSpotConversations.widget.refresh({
          openToNewThread: false,
        })
    }
  }, [router.pathname])

  return null
}
