// ストレージ用ヘルパー
// TODO: 型を制御してstring以外も受け入れる。(Store自体はオブジェクトも入れられるため)
import { StoreNames, StoreValue } from "@kvs/types"
import { AppStorage } from "./store"
import cache from "memory-cache"
import { Store } from "./store"

export const storeDel = async (key: string): Promise<void> => {
  cache.del(key)
  await (await Store()).delete(key)
}

export const storeGet: <S extends AppStorage, K extends StoreNames<S>>(
  key: K
) => Promise<StoreValue<S, K> | undefined> = async (key) => {
  return cache.get(key) ?? ((await (await Store()).get(key)) as any) ?? null
}

export const storeSet: <S extends AppStorage, K extends StoreNames<S>>(
  key: K,
  value: StoreValue<S, K> | undefined
) => Promise<typeof value> = async (key, value) => {
  await (await Store()).set(key, cache.put(key, value))
  return value
}
