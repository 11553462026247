import { SeverityLevel } from "@sentry/nextjs"

/** 共通カスタムエラークラス */
export abstract class BaseError extends Error {
  constructor(e?: string, options?: ErrorOptions) {
    super(e, options)
    this.name = new.target.name
    // 下記の行はTypeScriptの出力ターゲットがES2015より古い場合(ES3, ES5)のみ必要
    Object.setPrototypeOf(this, new.target.prototype)
  }
}

export type Level = SeverityLevel

export type LeveledError = { level?: Level }

export class UnreachableCaseError extends BaseError {
  constructor(e: never, msg?: string) {
    super(`unreachable: ${[msg, JSON.stringify(e)].filter(Boolean).join(",")}`)
  }
}

export function isErrorWithCode(e: any): e is Error & { code: string } {
  return e && e instanceof Error && "code" in e
}
