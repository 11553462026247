import { getColor, mode, transparentize } from "@chakra-ui/theme-tools"
import {
  ComponentStyleConfig,
  ComponentSingleStyleConfig,
} from "@chakra-ui/react"

const baseStyle: ComponentSingleStyleConfig["baseStyle"] = (props) => {
  return {
    borderRadius: "full",
    fontWeight: "bold",
    _disabled: {
      opacity: 0.4,
      cursor: "not-allowed",
      boxShadow: "none",
    },
    _hover: {
      _disabled: {
        bg: "initial",
      },
    },
  }
}

export const generalColorMap = {
  primary: {
    color: "white",
    bg: "ui.main",
  },
  secondary: {
    color: "white",
    bg: "ui.secondary",
  },
  white: {
    color: "text.main",
    bg: "white",
    borderColor: "ui.main",
  },
  gray: {
    color: "text.black",
    bg: "bg.gray",
  },
  general: {
    bg: "ui.general",
  },
  sub: {
    bg: "ui.sub",
    color: "white",
  },
}

const variantSolid = (props: Record<string, any>) => {
  const { colorScheme: c, theme } = props
  if (!(c in generalColorMap)) {
    return
  }
  const { bg } = generalColorMap[c]

  return {
    bg,
    _hover: {
      bg: `linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), ${getColor(
        theme,
        bg
      )}`,
      _disabled: {
        bg,
      },
    },
  }
}

const variantTertiary = (props: Record<string, any>) => {
  const { colorScheme: c, theme } = props

  if (!(c in generalColorMap)) {
    return
  }
  const {
    bg = "bg.gray",
    color = "text.black",
    borderColor,
  } = generalColorMap[c]

  return {
    color,
    bg,
    border: borderColor ? "2px solid" : undefined,
    borderColor,
    boxShadow: "tertiary",
    _hover: {
      bg: `linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), ${getColor(
        theme,
        bg
      )}`,
      boxShadow: `tertiary_hover`,
    },
  }
}

/** Tertiaryのゆがみが無いバージョン */
const variantTertiaryPlain = (props: Record<string, any>) => {
  const { colorScheme: c, theme } = props

  if (!(c in generalColorMap)) {
    return
  }
  const {
    bg = "bg.gray",
    color = "text.black",
    borderColor,
  } = generalColorMap[c]

  return {
    color,
    bgColor: bg,
    border: borderColor ? "2px solid" : undefined,
    borderColor,
    // boxShadow: "tertiary_plain",
    _hover: {
      bg: `linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), ${getColor(
        theme,
        bg
      )}`,
    },
  }
}

// Copy from: https://github.com/chakra-ui/chakra-ui/blob/de4cffb1638e8d10cd74515b0289d70eb94931be/packages/theme/src/components/button.ts#L24
function _variantGhost(props: Record<string, any>) {
  const { colorScheme: c, theme } = props
  if (!(c in generalColorMap)) {
    return
  }
  const { color = "text.black" } = generalColorMap[c]

  if (c === "gray") {
    return {
      color,
      _hover: {
        bg: mode(`gray.100`, `whiteAlpha.200`)(props),
      },
      _active: { bg: mode(`gray.200`, `whiteAlpha.300`)(props) },
    }
  }

  const darkHoverBg = transparentize(`${c}.200`, 0.12)(theme)
  const darkActiveBg = transparentize(`${c}.200`, 0.24)(theme)

  return {
    color,
    bg: "transparent",
    _hover: {
      bg: mode(`${c}.50`, darkHoverBg)(props),
    },
    _active: {
      bg: mode(`${c}.100`, darkActiveBg)(props),
    },
  }
}

const variantOutline = (props: Record<string, any>) => {
  const { colorScheme: c } = props

  if (!(c in generalColorMap)) {
    return
  }
  const { bg = "bg.gray" } = generalColorMap[c]

  return {
    color: c === "gray" ? "text.black" : c === "white" ? "text.main" : bg,
    borderColor: c === "gray" ? "line.gray" : c === "white" ? "ui.main" : bg,
    _hover: {
      color: c === "gray" ? "text.black" : "#fff",
      bgColor: c === "gray" ? "#edf2f7" : c === "white" ? "ui.main" : bg,
    },
    _disabled: {
      color: c === "gray" ? "text.black" : c === "white" ? "text.main" : bg,
    },
  }
}

const variantOutlineBold = (props: Record<string, any>) => {
  const { colorScheme: c } = props
  const color = { white: "white" }[c] ?? "text.black"
  const borderColor = { gray: "line.gray", white: "white" }[c] ?? "currentColor"
  const hoverBgColor =
    { gray: "line.gray", white: "bg.disabled" }[c] ?? "currentColor"

  return {
    ..._variantGhost({ ...props, color: props.color ?? "white" }),
    color,
    border: "2px solid",
    borderColor,
    _hover: {
      bg: hoverBgColor,
    },
  }
}

const variantUnderline = (props: Record<string, any>) => {
  const { colorScheme: c } = props
  return {
    padding: 0,
    height: "auto",
    lineHeight: "normal",
    color: "ui.black",
    textDecoration: "underline",
    fontWeight: "normal",
    _hover: {
      _disabled: {
        textDecoration: "none",
      },
    },
    _active: {
      color: mode(`${c}.700`, `${c}.500`)(props),
    },
  }
}

const variantUnstyled = {
  bg: "none",
  color: "inherit",
  display: "inline",
  lineHeight: "inherit",
  fontWeight: "inherit",
  m: 0,
  p: 0,
}

const variants = {
  solid: variantSolid,
  outline: variantOutline,
  outlineBold: variantOutlineBold,
  tertiary: variantTertiary,
  tertiary_plain: variantTertiaryPlain,
  underline: variantUnderline,
  unstyled: variantUnstyled,
}

const sizes = {
  xl: {
    h: 12,
    minW: 12,
    fontSize: "lg",
    px: 5,
  },
  lg: {
    h: 10,
    minW: 10,
    fontSize: "lg",
    px: 5,
  },
  md: {
    h: 8,
    minW: 8,
    fontSize: "xs",
    px: 3,
  },
  mid: {
    h: 7,
    minW: 7,
    fontSize: "xs",
    px: 3,
  },
  sm: {
    h: "30px",
    minW: "30px",
    fontSize: "xs",
    // px: 3,
    px: 2,
  },
  xs: {
    h: 6,
    minW: 6,
    fontSize: "xs",
    px: 1,
  },
}

export default { baseStyle, variants, sizes } as ComponentStyleConfig
