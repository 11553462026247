import { useRouter } from "next/router"
import Script from "next/script"
import React, { useEffect } from "react"
import {
  AppGTMDatalayerPush,
  AppGTMPageView,
  saveSourcePage,
  SmartnewsAdsPageView,
  withHubSpot,
} from "../lib/track"
import { HubSpotChat } from "./hubSpotChat"

export type GoogleTagManagerProps = {
  /** GTM ID
   *
   * 指定が無い場合は何もしません
   */
  id?: string
  /** GTMの環境ごとの設定
   *
   * 詳細: https://support.google.com/tagmanager/answer/6311518?hl=ja
   * 本番環境には Live（公開中）の値を設定してください。
   */
  auth?: string
  preview?: string
}

/** Google Tag Managerのタグ
 *
 * Bodyのすぐ下に挿入してください。
 * また、下記URLを参考にTagManagerを設定してください。
 *
 * - SPA用の設定: https://note.com/kensuke_tsutsumi/n/n563258e54dac
 * - 外部リンクのトラッキング用設定: https://asiastars.org/%E3%82%BF%E3%82%B0%E3%83%9E%E3%83%8D/%E3%82%BF%E3%82%B0%E3%83%9E%E3%83%8D%E3%81%A7%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E8%A8%AD%E5%AE%9A%E5%85%B7%E4%BD%93%E4%BE%8B/
 * - クロスドメインの設定: https://www.officeants.com/blog/googleanalytics-crossdomain-tracking.html#mokuji4
 *  */
export const GoogleTagManager: React.FC<GoogleTagManagerProps> = ({
  id,
  auth = "",
  preview = "",
}) => {
  useEffect(() => {
    // 初期化
    window.dataLayer ||= []
    if (process.env.NODE_ENV === "development" && typeof window === "object") {
      console.groupCollapsed(`[dataLayer] initialized`)
      console.log(window.dataLayer)
      console.groupEnd()
    }
  }, [])
  if (!id) return null

  const gtm_auth = `&gtm_auth=${auth}`
  const gtm_preview = `&gtm_preview=${preview}`
  const script = `
      (function(w,d,s,l,i){w[l]=w[l]||[];
        w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
        var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
        j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl+'${gtm_auth}${gtm_preview}&gtm_cookies_win=x';
        f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${id}');
  `
  return (
    <Script id="gtm" strategy="afterInteractive">
      {script}
    </Script>
  )
}

export const HubSpotTrackerTag: React.FC<{ hubID?: string }> = (props) => {
  useEffect(() => {
    // 初期化
    window._hsq ||= []
    if (process.env.NODE_ENV === "development" && typeof window === "object") {
      console.groupCollapsed(`[HubSpot] initialized`)
      console.log(window._hsq)
      console.groupEnd()
    }
  }, [])
  if (!props.hubID) return null
  return (
    <>
      <Script
        // HubSpot
        // @see https://developers.hubspot.jp/docs/api/events/tracking-code
        id="hs-script-loader"
        strategy="afterInteractive"
        src={`https://js.hs-scripts.com/${props.hubID}.js`}
      />
      <Script
        // LITTLE HELP CONNECT
        // @see https://knowledge.littlehelp.co.jp/ja/crm/contact/tracking
        id="hs-lhc"
        strategy="afterInteractive"
        src="https://connect.littlehelp.co.jp/storage/lh-tracking.js"
      />
      {/* NOTE: HubSpotChat @see https://app.hubspot.com/chatflows/21488546/ */}
      <HubSpotChat />
    </>
  )
}

/**
 * SmartNews Adのトラッキングタグ
 * GTMで設定していたがうまくトラッキングできない（計測しているCVの数値が一致しない）ため、直接設定する
 */
const SmartNewsTrackerTag = ({ id }: { id?: string }) => {
  if (!id) return null

  const script = `
      !function(){if(window.SmartnewsAds=window.SmartnewsAds||{},!window.SmartnewsAds.p){var e=window.SmartnewsAds.p=function(){e.callMethod?e.callMethod.apply(e,arguments):e.queue.push(arguments)};e.push=e,e.version="1.0.1",e.queue=[];var n=document.createElement("script");n.async=!0,n.src="https://cdn.smartnews-ads.com/i/pixel.js";var s=document.getElementsByTagName("script")[0];s.parentNode.insertBefore(n,s)}}();
SmartnewsAds.p("${id}", "PageView");
  `
  return (
    <Script id="smartnewsAds" strategy="afterInteractive">
      {script}
    </Script>
  )
}

const trackPageView = (() => {
  // ISR/SSGやクエリパラメーターによって挙動が異なるので前回とlocation.hrefの値が違う場合に送る
  // https://github.com/zeit/next.js/issues/11639
  let last: string | null = null
  return () => {
    // タイトルの書き換えがあるのでsetTimeoutで遅らせる
    setTimeout(() => {
      let pathname = location.pathname
      let search = location.search
      let hash = location.hash

      // 送信したくないパスをフィルターする
      if (pathname.indexOf("/auth") === 0) {
        hash = ""
        search = ""
      }

      const path = pathname + search + hash
      if (last !== path) {
        AppGTMPageView(path, document.title)
        // スクリプトの読み込み完了時は自動で送信されるので、それ以降のみ実行
        if (last) {
          // タイトル等は自動取得に任せて、ページビューのイベントのみ送信する
          withHubSpot((hs) => {
            hs(["trackPageView"])
          })
          SmartnewsAdsPageView(process.env.NEXT_PUBLIC_SMARTNEWS_ADS_ID)
        }
      }
      last = path
    }, 0)
  }
})()

/** ランディングページ記録用 */
export const SourceTracker = () => {
  useEffect(() => {
    saveSourcePage()
  }, [])
  return null
}

/** トラッキング(GTM, HubSpot) */
export const AppTracker = ({
  getGTMInitialVars,
}: {
  /** GTMにPV前に設定するデータレイヤー変数 */
  getGTMInitialVars?: () => Record<string, string | number> | undefined
}) => {
  const router = useRouter()
  useEffect(() => {
    const vars = getGTMInitialVars?.()
    vars && AppGTMDatalayerPush(vars)
    trackPageView()
  }, [getGTMInitialVars])

  useEffect(() => {
    router.events.on("routeChangeComplete", trackPageView)
    return () => {
      router.events.off("routeChangeComplete", trackPageView)
    }
  }, [router.events])

  return (
    <>
      <HubSpotTrackerTag hubID={process.env.NEXT_PUBLIC_HUBID} />
      <GoogleTagManager
        id={process.env.NEXT_PUBLIC_GTM_ID}
        auth={process.env.NEXT_PUBLIC_GTM_AUTH}
        preview={process.env.NEXT_PUBLIC_GTM_PREVIEW}
      />
      <SmartNewsTrackerTag id={process.env.NEXT_PUBLIC_SMARTNEWS_ADS_ID} />
    </>
  )
}
