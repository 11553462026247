/** Code generated by gen-figma-tokens.mjs; DO NOT EDIT.
 *
 * Figma references:
 * - https://www.figma.com/file/jJdzL58hVljGH9HZVv9JBg/?node-id=0%3A26
 *
 */

// エディターで補完されない場合は `pnpm g:theme-typings` を実行した後以下のimport先にジャンプしてください
import type { ThemeTypings } from "@chakra-ui/styled-system/dist/theming.types"

const generatedTextStyles = {
  /** (A) Caption 1 - 12px 16px
   */
  "(a)-caption-1---12px-16px": {
    fontSize: "12px",
    lineHeight: "16px",
    fontWeight: "normal",
    letterSpacing: "0.033em",
  },

  /** 10-reg
   */
  "10-reg": {
    fontSize: "10px",
    lineHeight: "17px",
    fontWeight: "normal",
    letterSpacing: "0.05em",
  },

  /** 11-default-bold
   */
  "11-default-bold": {
    fontSize: "11px",
    lineHeight: "13px",
    fontWeight: "bold",
  },

  /** 12-bold
   */
  "12-bold": {
    fontSize: "12px",
    lineHeight: "18px",
    fontWeight: "bold",
    letterSpacing: "0.05em",
  },

  /** 12-default-bold
   */
  "12-default-bold": {
    fontSize: "12px",
    lineHeight: "14px",
    fontWeight: "bold",
  },

  /** 12-reg
   */
  "12-reg": { fontSize: "12px", lineHeight: "18px", fontWeight: "normal" },

  /** 12-underline
   */
  "12-underline": {
    fontSize: "12px",
    lineHeight: "18px",
    fontWeight: "normal",
    textDecoration: "underline",
  },

  /** 13-bold
   */
  "13-bold": {
    fontSize: "13px",
    lineHeight: "22px",
    fontWeight: "bold",
    letterSpacing: "0.05em",
  },

  /** 14-bold
   */
  "14-bold": {
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: "bold",
    letterSpacing: "0.05em",
  },

  /** 14-reg
   */
  "14-reg": {
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: "normal",
    letterSpacing: "0.05em",
  },

  /** 16-bold
   */
  "16-bold": {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "bold",
    letterSpacing: "0.05em",
  },

  /** 16-Bold-Head
   */
  "16-bold-head": {
    fontSize: "16px",
    lineHeight: "30.399999618530273px",
    fontWeight: "bold",
    letterSpacing: "0.04em",
  },

  /** 16-reg
   */
  "16-reg": {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "normal",
    letterSpacing: "0.05em",
  },

  /** 18-bold
   */
  "18-bold": {
    fontSize: "18px",
    lineHeight: "28px",
    fontWeight: "bold",
    letterSpacing: "0.08em",
  },

  /** 20-bold
   */
  "20-bold": {
    fontSize: "20px",
    lineHeight: "28px",
    fontWeight: "bold",
    letterSpacing: "0.08em",
  },

  /** 24-bold
   */
  "24-bold": {
    fontSize: "24px",
    lineHeight: "34px",
    fontWeight: "bold",
    letterSpacing: "0.08em",
  },

  /** 30-bold
   */
  "30-bold": {
    fontSize: "30px",
    lineHeight: "42px",
    fontWeight: "bold",
    letterSpacing: "0.08em",
  },

  /** base/H1
   */
  "base/h1": {
    fontSize: "23px",
    lineHeight: "30px",
    fontWeight: "normal",
    letterSpacing: "0.08em",
  },

  /** base/H2
   */
  "base/h2": {
    fontSize: "18px",
    lineHeight: "28px",
    fontWeight: "normal",
    letterSpacing: "0.08em",
  },

  /** base/H3
   */
  "base/h3": {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "bold",
    letterSpacing: "0.04em",
  },

  /** base/H4
   */
  "base/h4": {
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: "bold",
    letterSpacing: "0.04em",
  },

  /** Body_14pt_PC
   */
  "body-14pt-pc": {
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: "normal",
    letterSpacing: "0.05em",
  },

  /** Body_14pt_SP
   */
  "body-14pt-sp": {
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: "normal",
    letterSpacing: "0.05em",
  },

  /** Body_14pt_Underline_SP
   */
  "body-14pt-underline-sp": {
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: "bold",
    letterSpacing: "0.05em",
    textDecoration: "underline",
  },

  /** Body_16pt_PC
   */
  "body-16pt-pc": {
    fontSize: "16px",
    lineHeight: "30px",
    fontWeight: "normal",
    letterSpacing: "0.05em",
  },

  /** BodyBold_14pt_PC
   */
  "bodybold-14pt-pc": {
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: "bold",
    letterSpacing: "0.05em",
  },

  /** BodyBold_14pt_SP
   */
  "bodybold-14pt-sp": {
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: "bold",
    letterSpacing: "0.05em",
  },

  /** BodyBold_16pt_PC
   */
  "bodybold-16pt-pc": {
    fontSize: "16px",
    lineHeight: "30px",
    fontWeight: "bold",
    letterSpacing: "0.05em",
  },

  /** BtnText_13pt_SP
   */
  "btntext-13pt-sp": {
    fontSize: "12px",
    lineHeight: "20px",
    fontWeight: "normal",
    letterSpacing: "0.05em",
  },

  /** button
   */
  button: {
    fontSize: "13px",
    lineHeight: "20px",
    fontWeight: "normal",
    letterSpacing: "0.05em",
  },

  /** H2_18pt_SP
   */
  "h2-18pt-sp": {
    fontSize: "18px",
    lineHeight: "28px",
    fontWeight: "normal",
    letterSpacing: "0.08em",
  },

  /** H2_30pt_PC
   */
  "h2-30pt-pc": {
    fontSize: "30px",
    lineHeight: "42px",
    fontWeight: "normal",
    letterSpacing: "0.08em",
  },

  /** H4_14pt_SP
   */
  "h4-14pt-sp": {
    fontSize: "14px",
    lineHeight: "24px",
    fontWeight: "bold",
    letterSpacing: "0.04em",
  },

  /** hero-title
   */
  "hero-title": {
    fontSize: "37px",
    lineHeight: "24px",
    fontWeight: "bold",
    letterSpacing: "0.04em",
  },

  /** md/H1
   */
  "md/h1": {
    fontSize: "32px",
    lineHeight: "50px",
    fontWeight: "normal",
    letterSpacing: "0.08em",
  },

  /** md/H2
   */
  "md/h2": {
    fontSize: "30px",
    lineHeight: "42px",
    fontWeight: "normal",
    letterSpacing: "0.08em",
  },

  /** md/H3
   */
  "md/h3": {
    fontSize: "22px",
    lineHeight: "34px",
    fontWeight: "normal",
    letterSpacing: "0.04em",
  },

  /** md/H4
   */
  "md/h4": {
    fontSize: "18px",
    lineHeight: "24px",
    fontWeight: "bold",
    letterSpacing: "0.08em",
  },
}
export default generatedTextStyles
