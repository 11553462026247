import { mode } from "@chakra-ui/theme-tools"
import { ComponentStyleConfig } from "@chakra-ui/react"
const parts = ["field", "icon"]

function baseStyleField(props: Record<string, any>) {
  return {
    width: "100%",
    outline: 0,
    position: "relative",
    transition: "all 0.2s",

    appearance: "none",
    paddingBottom: "1px",
    lineHeight: "normal",
    "> option, > optgroup": {
      bg: mode("white", "gray.700")(props),
    },
  }
}

const baseStyleIcon = {
  width: "1.5rem",
  height: "100%",
  right: "0.5rem",
  position: "relative",
  color: "currentColor",
  fontSize: "1.25rem",
  _disabled: {
    opacity: 0.5,
  },
}

const baseStyle = (props: Record<string, any>) => ({
  field: baseStyleField(props),
  icon: baseStyleIcon,
})

const variants = {
  filledOutlineRound: variantFilledOutlineRound,
  filledInvertRound: variantFilledInvertRound,
}

function variantFilledOutlineRound(props: Record<string, any>) {
  return {
    field: {
      borderRadius: "100px", // とにかく角を丸くしたい
      border: "2px solid",
      borderColor: "inherit",
      bg: "bg.gray2",
      _hover: {
        bg: "bg.gray",
      },
      _readOnly: {
        boxShadow: "none !important",
        userSelect: "all",
      },
      _disabled: {
        opacity: 0.4,
        cursor: "not-allowed",
      },
      _focus: {
        bg: "transparent",
        borderColor: "ui.secondary",
      },
      _invalid: {
        borderColor: "ui.main",
      },
    },
    addon: {
      border: "2px solid",
      borderColor: "transparent",
      bg: mode("gray.100", "whiteAlpha.50")(props),
    },
  }
}

/** 3分投資診断のチャットボットUIで使用したセレクトボックス
 * 色は固定で、選択肢の色はデフォルト
 */
function variantFilledInvertRound(props: Record<string, any>) {
  return {
    field: {
      border: "2px solid",
      borderRadius: "10px",
      borderColor: "transparent",
      boxShadow: "#F75E29 0px 2px 2px 0px",
      color: "white",
      fontWeight: "bold",
      bg: "ui.sub",
      "> option, > optgroup": {
        color: "initial",
      },
      _hover: {
        cursor: "pointer",
        // bg: "ui.sub",
        boxShadow: "#F75E29 0px 1px 2px 0px",
      },
      _readOnly: {
        boxShadow: "none !important",
        userSelect: "all",
      },
      _disabled: {
        opacity: 0.4,
        cursor: "not-allowed",
      },
      _invalid: {},
      _focus: {
        boxShadow: "#F75E29 0px 1px 2px 0px",
      },
    },
    icon: {
      color: "white",
    },
  }
}

export default {
  parts,
  baseStyle,
  variants,
} as ComponentStyleConfig
