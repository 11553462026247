/** 認証状態 */
export type AuthState =
  | AuthStateUnchecked
  | AuthStateCustomer
  | AuthStateAnonymous
  | AuthStateGuest

export type AuthStateChecked =
  | AuthStateCustomer
  | AuthStateAnonymous
  | AuthStateGuest

export const InitialAuthState: AuthState = { type: "unchecked", provider: "" }

type AuthStateCommon = {
  provider: string
}

/** 未確認
 *
 * SSR時は常にこの状態
 */
export type AuthStateUnchecked = AuthStateCommon & {
  type: "unchecked"
  logout?: undefined
}

/** ログイン済み */
export type AuthStateCustomer = AuthStateCommon & {
  type: "customer"
  rawValue: any
  logout: LogoutHandler
}

/** 匿名ログイン済み */
export type AuthStateAnonymous = AuthStateCommon & {
  type: "anonymous"
  rawValue: any
  logout: LogoutHandler
}

/** 未ログイン */
export type AuthStateGuest = AuthStateCommon & {
  type: "guest"
  logout?: undefined
}

type LogoutHandler = () => Promise<unknown>
