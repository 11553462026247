import { requestIdleCallback } from "next/dist/client/request-idle-callback"

/** Promiseを結果もしくはエラーのPromiseに変換する */
export async function asResult<T, E = Error>(
  r: Promise<T>
): Promise<{ result: T; error: null } | { result: null; error: E }> {
  try {
    const r_1 = await r
    return { result: r_1, error: null }
  } catch (e) {
    return { result: null, error: e as E }
  }
}

/** スリープ
 * resolveした値はundefinedになります
 */
export const Sleep = (ms?: number): Promise<undefined> =>
  new Promise((r) => setTimeout(r, ms))

export const NextTick = async <T>(a: T): Promise<Awaited<T>> => {
  await new Promise((r) => requestIdleCallback(r)).catch(() => {})
  return await a
}

type PromiseMap = { [key: string]: Promise<unknown> | unknown }
type PromiseMapAwaited<T extends PromiseMap> = {
  [K in keyof T]: Awaited<T[K]>
}

export const PromiseProps = async <T extends PromiseMap>(
  obj: T
): Promise<PromiseMapAwaited<T>> =>
  Object.fromEntries(
    await Promise.all(Object.entries(obj).map(async ([k, v]) => [k, await v]))
  )
