import Head from "next/head"
import React from "react"
import { getPageTitle, TitleSuffixOption } from "./getTitle"

export type MetaTagProps = {
  title?: string | null
  /** タイトルの接尾語
   * 'simple', 'full' もしくは任意の文字列を指定可能
   * falseを指定すると付与しません
   * @default simple
   */
  titleSuffix?: TitleSuffixOption
  description?: string | null
  ogUrl?: string | null
  ogImage?: string | null
  robots?: string | null

  prev?: string | null
  next?: string | null

  jsonLD?: object
} & (
  | {
      noindex: true
      canonical?: never // noindex指定時はcanonicalは指定できない
    }
  | {
      noindex?: false
      canonical: string | null // 明示的な未指定は許可
    }
)

export const MetaTag: React.FC<MetaTagProps> = ({
  titleSuffix = "simple",
  ...props
}) => {
  const finalTitle = props.title ? getPageTitle(props.title, titleSuffix) : null
  return (
    <Head>
      {props.noindex ? (
        <meta key="robots" name="robots" content="noindex" />
      ) : (
        props.robots && (
          <meta key="robots" name="robots" content={props.robots} />
        )
      )}
      {props.canonical && (
        <link key="canonical" rel="canonical" href={props.canonical} />
      )}
      {finalTitle && (
        <>
          <title key="title">{finalTitle}</title>
          <meta key="og:title" property="og:title" content={finalTitle} />
        </>
      )}
      {props.description && (
        <>
          <meta
            key="description"
            name="description"
            content={props.description}
          />
          <meta
            key="og:description"
            property="og:description"
            content={props.description}
          />
        </>
      )}
      {(props.ogUrl || props.canonical) && (
        <meta
          key="og:url"
          property="og:url"
          content={(props.ogUrl ?? props.canonical)!}
        />
      )}
      {props.ogImage && (
        <meta key="og:image" property="og:image" content={props.ogImage} />
      )}
      {props.prev && <link key="prev" rel="prev" href={props.prev} />}
      {props.next && <link key="next" rel="next" href={props.next} />}
      {props.jsonLD && (
        <script
          key="jsonld"
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(props.jsonLD),
          }}
        />
      )}
    </Head>
  )
}
