import dynamic from "next/dynamic"
import React from "react"

const Provider = dynamic(() =>
  import("./authStateProvider").then((r) => r.AuthStateFirebaseProvider)
)

const Modal = dynamic(() =>
  import("./authStateProvider").then((r) => r.AuthStateFirebaseLoginModal)
)

export const AuthStateFirebaseProviderDynamic = ({
  disabled,
  ...props
}: React.PropsWithChildren<any>) => {
  return <Provider {...props} />
}

export const AuthStateFirebaseLoginModalDynamic = ({
  disabled,
}: {
  disabled?: boolean
}) => {
  if (disabled) return null
  return <Modal />
}
