import { mode } from "@chakra-ui/theme-tools"
import {
  ComponentStyleConfig,
  ComponentSingleStyleConfig,
} from "@chakra-ui/react"

const baseStyle: ComponentSingleStyleConfig["baseStyle"] = {
  field: {
    width: "100%",
    outline: 0,
    position: "relative",
    appearance: "none",
    transition: "all 0.2s",
  },
}

const defaultProps = {
  textStyle: "body",
  variant: "filled",
}

const variants = {
  filled: variantFilled,
}

function variantFilled(props: Record<string, any>) {
  return {
    field: {
      borderRadius: "5px",
      border: "2px solid",
      borderColor: "transparent",
      bg: "bg.gray2",
      _hover: {
        bg: "bg.gray",
      },
      _readOnly: {
        boxShadow: "none !important",
        userSelect: "all",
      },
      _disabled: {
        opacity: 0.4,
        cursor: "not-allowed",
      },
      _focus: {
        bg: "transparent",
        borderColor: "ui.secondary",
      },
      _invalid: {
        borderColor: "ui.main",
      },
    },
    addon: {
      border: "2px solid",
      borderColor: "transparent",
      bg: mode("gray.100", "whiteAlpha.50")(props),
    },
  }
}

export default { baseStyle, variants, defaultProps } as ComponentStyleConfig
