const ModalTheme = {
  /**
   * iOSでFullModal表示した時の隙間を解消するためのworkaround
   * EX-30
   * @see https://github.com/chakra-ui/chakra-ui/issues/4680
   */
  baseStyle: {
    dialogContainer: {
      "@supports(height: -webkit-fill-available)": {},
    },
  },
}

export default ModalTheme
