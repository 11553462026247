import {
  ModalOverlayProps,
  ModalProps,
  useDisclosure,
  useLatestRef,
} from "@chakra-ui/react"
import constate from "constate"
import { AuthState } from "lib/auth/user"
import React, { useCallback, useRef, useMemo } from "react"
import type { LoginModalOption } from "./firebase/ui/LoginModal"

export type AuthContext = AuthState & {}

/** モーダルの挙動を変更するためのProps
 * 開くタイミングでのみ調整可能
 */
export type AuthLoginModalProps = {
  modal: Partial<ModalProps>
  overlay: Partial<ModalOverlayProps>
  option: Partial<LoginModalOption>
}

export const [AuthStateProvider, useAuthState, useSetAuth] = constate(
  ({ value }: { value: AuthState }) => {
    const [state, setState] = React.useState<AuthContext>(value)
    const latestState = useLatestRef(state)
    const authState = useMemo(() => {
      const lstate = latestState.current
      return {
        ...lstate,
        authChecked: state.type !== "unchecked",
        authUser: lstate.type === "customer",
        authInternal: state.type === "customer" || state.type === "anonymous",
      }
    }, [latestState, state.provider, state.type])
    return {
      authState,
      setAuth: useCallback(
        (v: AuthState) =>
          setState({
            ...v,
          }),
        []
      ),
    }
  },
  (v) => v.authState,
  (v) => v.setAuth
)

export const [
  LoginModalProvider,
  useLoginModalDisclosure,
  useLoginModalMethod,
  useLoginModalProps,
] = constate(
  () => {
    const disclosure = useDisclosure()
    const props = useRef<AuthLoginModalProps>({
      modal: {},
      overlay: {},
      option: {},
    })

    return {
      disclosure,
      props,
    }
  },
  (v) => v.disclosure,
  (v) => ({
    onOpen: v.disclosure.onOpen,
    onClose: v.disclosure.onClose,
    onToggle: v.disclosure.onToggle,
  }),
  (v) => v.props
)

/** ログインしているかを返します
 * - undefined: まだわかりません
 * - true: ログインしています
 * - false: ログインしていません
 */
export const useAuthenticated = () => {
  const s = useAuthState()
  return s.authChecked ? !!s.authUser : undefined
}

export const useCurrentUser = () => {
  const { authChecked, authUser, authInternal } = useAuthState()
  return [authChecked, authUser, authInternal] as [
    /** 認証を確認したか */
    authChecked: typeof authChecked,
    /** ユーザーから見てログインしているか。匿名認証は含まない */
    authUser: typeof authUser,
    /** システム的に認証済みであるか。匿名認証を含む */
    authInternal: typeof authInternal
  ]
}
