/** 認証系のエラーを管理するエラークラスとヘルパー */
import { SeverityLevel } from "@sentry/nextjs"
import { LiteralUnion } from "@~/common/lib/typeutil"
import type { FirebaseError } from "firebase/app"
import { BaseError, Level, LeveledError } from "lib/error"
import {
  isOIDCAuthError,
  OIDCAuthErrorCode,
  OIDCAuthErrorCodeDetails,
} from "lib/oidc/error"

type AuthErrorCode =
  | InternalAuthErrorCode
  | FirebaseAuthErrorCode
  | OIDCAuthErrorCode

export type AuthErrorCodes = LiteralUnion<AuthErrorCode, string>

export type AuthErrorDetail<Code = AuthErrorCodes> =
  Code extends keyof InternalAuthErrorDetails
    ? InternalAuthErrorDetails[Code]
    : Code extends keyof OIDCAuthErrorCodeDetails
    ? OIDCAuthErrorCodeDetails[Code]
    : null

export type SerializedAuthError<Code = AuthErrorCodes | "unknown"> =
  Code extends AuthErrorCodes
    ? [code: Code, detail: AuthErrorDetail<Code>]
    : ["unknown", null]

export const isSerializedAuthError = (
  error?: any
): error is SerializedAuthError =>
  error &&
  Array.isArray(error) &&
  error.length === 2 &&
  typeof error[0] === "string"

/** エラーのインスタンスをURLで運べるようににします */
export const serializeAuthError = (error: Error): SerializedAuthError<any> => {
  if (isFirebaseError(error)) {
    return [error.code, null] as any
  }
  if (isOIDCAuthError(error)) {
    return [error.code, error.data] as any
  }
  if (isInternalAuthError(error)) {
    return [error.code, { message: error.message }] as any
  }
  return (
    typeof error["code"] === "string"
      ? [error["code"], null]
      : ["unknown", null]
  ) as any
}

/** URL用にエンコードされたエラーコード */
export type EncodedAuthErrorCode = `${encodedPrefixes}.${string}`
type encodedPrefixes = "fb" | "oidc" | "auth"

/** 文字列にしたエラーコードからエラーコードの型の値に変換します
 */
export const decodeAuthErrorCode = (
  errorString: LiteralUnion<EncodedAuthErrorCode, string>
): AuthErrorCodes => {
  const [prefix, body] = errorString.split(".", 2) as [
    prefix: encodedPrefixes,
    body: string
  ]
  switch (prefix) {
    case "fb":
    case "oidc":
    case "auth":
      return body
    default:
      return errorString
  }
}

export type InternalAuthErrorCode =
  | "not-registered" // ADMIN登録無しでログインした。 => 新規登録に誘導する
  | "cancel" // キャンセルされた
  | "callback/failed" // コールバックされてきたがlFirebaseにログインできなかった
  | "callback/precondition-error" // コールバックされてきたがトークンなど前提条件が揃わなくてFirebaseにログインできなかった
  | "email/different-device" // メールログインを行ったがメールアドレスが正しくない => 再度メールリンク認証してもらう
  | "email/invalid-link" // 古いメールログインURLか、使用済みのURL => 再度メールリンク認証してもらう
  | "oidc/integration" // oidcとFirebaseのやりとりのエラー
  | "admin/signup-failed" // Admin側でエラー
  | "admin/signup/email-already-in-use" // Admin側メールアドレスが使われているので登録不可

export type InternalAuthErrorDetails = {
  [K in InternalAuthErrorCode]: never
}

export class InternalAuthError extends BaseError implements LeveledError {
  public code: InternalAuthErrorCode

  constructor(code: InternalAuthErrorCode, message?: string) {
    super(message ? `${code}, ${message}` : code)
    this.name = "InternalAuthError"
    this.code = code
  }

  get level() {
    // ユーザーのキャンセルや、非エラーの場合はwarning
    if (this.code === "cancel" || this.code === "not-registered") {
      return "warning"
    }
    return undefined
  }

  static errorWithCode(code: InternalAuthErrorCode, message?: string | Error) {
    return new InternalAuthError(code, message?.toString())
  }
}

export const isAuthError = (
  error: any
): error is Error & { code: AuthErrorCodes } => {
  return (
    isInternalAuthError(error) ||
    isFirebaseError(error) ||
    isOIDCAuthError(error)
  )
}

export const isInternalAuthError = (error: any): error is InternalAuthError => {
  return error && error instanceof InternalAuthError
}

export const isFirebaseError = (
  error: any
): error is FirebaseError & {
  code: FirebaseAuthErrorCode
} => {
  // error instanceof FirebaseError にすると余計なファイルがバンドルに含まれるので instanceof は使わずに判定しています
  return error && error instanceof Error && error.name === "FirebaseError"
}

export const firebaseErrorLevel = (error: FirebaseError): Level | undefined => {
  switch (error.code as FirebaseClientAuthErrorCode) {
    // ユーザーのキャンセルや、非エラーの場合はwarning
    case "auth/user-cancelled":
    // どうしようもないエラー
    case "auth/expired-action-code":
    case "auth/invalid-action-code":
      return "warning"
    default:
      return undefined
  }
}

/** v9でも型としてexportされていないのでこのまま扱う
 * https://github.com/firebase/firebase-js-sdk/blob/cdada6c68f9740d13dd6674bcb658e28e68253b6/packages/auth/src/core/errors.ts#L450
 */
export type FirebaseAuthErrorCode =
  | FirebaseClientAuthErrorCode
  | FirebaseAdminAuthErrorCode

type FirebaseClientAuthErrorCode =
  | "auth/admin-restricted-operation"
  | "auth/argument-error"
  | "auth/app-not-authorized"
  | "auth/app-not-installed"
  | "auth/captcha-check-failed"
  | "auth/code-expired"
  | "auth/cordova-not-ready"
  | "auth/cors-unsupported"
  | "auth/credential-already-in-use"
  | "auth/custom-token-mismatch"
  | "auth/requires-recent-login"
  | "auth/dependent-sdk-initialized-before-auth"
  | "auth/dynamic-link-not-activated"
  | "auth/email-change-needs-verification"
  | "auth/email-already-in-use"
  | "auth/emulator-config-failed"
  | "auth/expired-action-code"
  | "auth/cancelled-popup-request"
  | "auth/internal-error"
  | "auth/invalid-api-key"
  | "auth/invalid-app-credential"
  | "auth/invalid-app-id"
  | "auth/invalid-user-token"
  | "auth/invalid-auth-event"
  | "auth/invalid-cert-hash"
  | "auth/invalid-verification-code"
  | "auth/invalid-continue-uri"
  | "auth/invalid-cordova-configuration"
  | "auth/invalid-custom-token"
  | "auth/invalid-dynamic-link-domain"
  | "auth/invalid-email"
  | "auth/invalid-emulator-scheme"
  | "auth/invalid-credential"
  | "auth/invalid-message-payload"
  | "auth/invalid-multi-factor-session"
  | "auth/invalid-oauth-client-id"
  | "auth/invalid-oauth-provider"
  | "auth/invalid-action-code"
  | "auth/unauthorized-domain"
  | "auth/wrong-password"
  | "auth/invalid-persistence-type"
  | "auth/invalid-phone-number"
  | "auth/invalid-provider-id"
  | "auth/invalid-recipient-email"
  | "auth/invalid-sender"
  | "auth/invalid-verification-id"
  | "auth/invalid-tenant-id"
  | "auth/multi-factor-info-not-found"
  | "auth/multi-factor-auth-required"
  | "auth/missing-android-pkg-name"
  | "auth/missing-app-credential"
  | "auth/auth-domain-config-required"
  | "auth/missing-verification-code"
  | "auth/missing-continue-uri"
  | "auth/missing-iframe-start"
  | "auth/missing-ios-bundle-id"
  | "auth/missing-or-invalid-nonce"
  | "auth/missing-multi-factor-info"
  | "auth/missing-multi-factor-session"
  | "auth/missing-phone-number"
  | "auth/missing-verification-id"
  | "auth/app-deleted"
  | "auth/account-exists-with-different-credential"
  | "auth/network-request-failed"
  | "auth/null-user"
  | "auth/no-auth-event"
  | "auth/no-such-provider"
  | "auth/operation-not-allowed"
  | "auth/operation-not-supported-in-this-environment"
  | "auth/popup-blocked"
  | "auth/popup-closed-by-user"
  | "auth/provider-already-linked"
  | "auth/quota-exceeded"
  | "auth/redirect-cancelled-by-user"
  | "auth/redirect-operation-pending"
  | "auth/rejected-credential"
  | "auth/second-factor-already-in-use"
  | "auth/maximum-second-factor-count-exceeded"
  | "auth/tenant-id-mismatch"
  | "auth/timeout"
  | "auth/user-token-expired"
  | "auth/too-many-requests"
  | "auth/unauthorized-continue-uri"
  | "auth/unsupported-first-factor"
  | "auth/unsupported-persistence-type"
  | "auth/unsupported-tenant-operation"
  | "auth/unverified-email"
  | "auth/user-cancelled"
  | "auth/user-not-found"
  | "auth/user-disabled"
  | "auth/user-mismatch"
  | "auth/user-signed-out"
  | "auth/weak-password"
  | "auth/web-storage-unsupported"
  | "auth/already-initialized"

type FirebaseAdminAuthErrorCode = "auth/email-already-exists"
