import { spacing } from "./spacing"

const largeSizes = {
  full: "100%",
  "3xs": "14rem",
  "2xs": "16rem",
  xs: "20rem",
  sm: "24rem",
  md: "28rem",
  lg: "32rem",
  xl: "36rem",
  "2xl": "42rem",
  "3xl": "48rem",
  "4xl": "56rem",
  "5xl": "64rem",
  "6xl": "72rem",
  "7xl": "80rem",

  btn: "150px",
}

const container = {
  /** SPコンテンツエリア, 大きなボタン */
  sp: "315px",

  sm: "640px",
  md: "768px",
  lg: "1024px",
  xl: "1280px",
  xl2: "1440px",

  // l-contentsだったもの
  w3l: "1152px",

  // PCコンテンツエリア(sm)
  contentSm: "710px",
  // PCコンテンツエリア(md)
  content: "825px",
  // PCコンテンツエリア(lg)
  contentLg: "855px",

  secondContent: "685px",
  thirdContent: "545px",
  columnContent: "405px",
  secondColumnContent: "335px",
  thirdColumnContent: "265px",
  forthColumnContent: "195px",

  pcWidget2: "345px",
  pcWidget: "395px",

  dialogButton: "150px",
}

export const sizes = {
  ...spacing,
  ...largeSizes,
  container,
}
