import invariant from "tiny-invariant"

/** オブジェクトから値がundefinedのものを取り除きます
 * - NOTE: 型をちゃんとすると(Omitすると)使いづらいかも?
 */
export const compact = <T extends object>(obj: T): T => {
  let cloned = { ...obj }
  Object.keys(cloned).forEach((k) => {
    if (cloned[k] === undefined) {
      delete cloned[k]
    }
  })
  return cloned
}

export const getOne = (query: { [key: string]: any }, key: string) => {
  return first(query[key])
}

export const first = <T extends any = any>(v: T | T[]): T => {
  if (Array.isArray(v)) {
    return v[0]
  }
  return v
}

export const pick = <T extends object, K extends keyof T>(
  object: T,
  ...keys: K[]
): Pick<T, K> => {
  return keys.reduce((obj, key) => {
    if (object && object.hasOwnProperty(key)) {
      obj[key] = object[key]
    }
    return obj
  }, {} as Pick<T, K>)
}

export const groupBy = <T>(arr: T[], criteria: (input: T) => string) =>
  arr.reduce(function (obj, item) {
    const k = typeof criteria === "function" ? criteria(item) : item[criteria]
    if (!obj.hasOwnProperty(k)) {
      obj[k] = []
    }
    obj[k].push(item)
    return obj
  }, {} as { [key: string]: T[] })

export const zip = <A extends any, B extends any>(a: A[], b: B[]): [A, B][] => {
  invariant(a.length === b.length, "配列の長さが違います")
  return a.map((aa, i) => [aa, b[i]])
}

export const chunk = <T extends any>(arr: T[], by: number) => {
  // Thanks to https://qiita.com/yarnaimo/items/e92600237d65876f8dd8
  return arr.reduce(
    (newarr, _, i) => (i % by ? newarr : [...newarr, arr.slice(i, i + by)]),
    [] as T[][]
  )
}

/** lodash.get
 *
 * @copyright https://stackoverflow.com/a/47058976/743842
 */
export const get = (obj: object, path: string, defaultValue = undefined) => {
  const travel = (regexp: RegExp) =>
    String.prototype.split
      .call(path, regexp)
      .filter(Boolean)
      .reduce(
        (res: any, key: string) =>
          res !== null && res !== undefined ? res[key] : res,
        obj
      )
  const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/)
  return result === undefined || result === obj ? defaultValue : result
}

export type PathsToStringProps<T> = T extends string
  ? []
  : {
      [K in Extract<keyof T, string>]: [K, ...PathsToStringProps<T[K]>]
    }[Extract<keyof T, string>]

export type Join<T extends string[], D extends string> = T extends []
  ? never
  : T extends [infer F]
  ? F
  : T extends [infer F, ...infer R]
  ? F extends string
    ? `${F}${D}${Join<Extract<R, string[]>, D>}`
    : never
  : string
