/** Code generated by gen-figma-tokens.mjs; DO NOT EDIT.
 *
 * Figma references:
 * - https://www.figma.com/file/ik8fFpMro81b8ZE3PskigP/?node-id=112%3A1
 *
 */

// エディターで補完されない場合は `pnpm g:theme-typings` を実行した後以下のimport先にジャンプしてください
import type { ThemeTypings } from "@chakra-ui/styled-system/dist/theming.types"

const generatedColors = {
  /** moneiro-color/darkblue
   * - rgba(20,31,132,1)
   */
  "moneiro-color-darkblue": "#141f83",

  /** moneiro-color/Fill-Black
   * - rgba(19,22,28,1)
   */
  "moneiro-color-fill-black": "#13151b",

  /** moneiro-color/Fill-Gray-Basic
   * - rgba(244,244,244,1)
   */
  "moneiro-color-fill-gray-basic": "#f4f4f4",

  /** moneiro-color/Fill-Gray-Sub
   * - rgba(233,233,233,1)
   */
  "moneiro-color-fill-gray-sub": "#e8e8e8",

  /** moneiro-color/Fill-Pale-Yellow
   * - rgba(255,241,225,1)
   */
  "moneiro-color-fill-pale-yellow": "#fff1e1",

  /** moneiro-color/Line-Gray-Basic
   * - rgba(231,231,231,1)
   */
  "moneiro-color-line-gray-basic": "#e7e7e7",

  /** moneiro-color/Line-Gray-Strong
   * - rgba(204,204,204,1)
   */
  "moneiro-color-line-gray-strong": "#cccccc",

  /** moneiro-color/main-orange
   * - rgba(247,94,41,1)
   */
  "moneiro-color-main-orange": "#f75e29",

  /** moneiro-color/sub-orange
   * - rgba(229,77,11,1)
   */
  "moneiro-color-sub-orange": "#e54d0b",

  /** moneiro-color/Text-Black
   * - rgba(34,34,34,1)
   */
  "moneiro-color-text-black": "#222222",

  /** moneiro-color/Text-Gray
   * - rgba(154,154,154,1)
   */
  "moneiro-color-text-gray": "#9a9a9a",

  /** moneiro-color/white
   * - rgba(255,255,255,1)
   */
  "moneiro-color-white": "#ffffff",

  /** moneiro-color/yellow
   * - rgba(255,159,53,1)
   */
  "moneiro-color-yellow": "#ff9f35",

  /** UI/Secondry
   * - rgba(20,31,132,1)
   */
  "ui-secondry": "#141f83",

  /** UI/Sub
   * - rgba(255,159,53,1)
   */
  "ui-sub": "#ff9f35",

  /** UI/Text/Main
   * - 文字サイズ16pt以下の時はこちら
   * - rgba(247,94,41,1)
   */
  "ui-text-main": "#f75e29",

  /** UI/Text/Main-dark
   * - 文字サイズ15pt以下の時はこちら
   * - rgba(229,77,11,1)
   */
  "ui-text-main-dark": "#e54d0b",
}
export default generatedColors
