/** @see base theme: https://github.com/chakra-ui/chakra-ui/tree/%40chakra-ui/core%400.7.0/packages/chakra-ui/src/theme */
import { colors } from "./colors"
import { components } from "./components"
import { layerStyles } from "./layerStyles"
import { sizes } from "./sizes"
import { spacing } from "./spacing"
import { textStyles } from "./textStyles"

/** ベーステーマ
 *
 * - ChakraProvider にそのまま渡すか、 extendTheme で拡張して使います
 * - `chakra-cli` が壊れるので next/font は使えません(extendTheme で拡張すること)
 **/
export const theme = {
  space: spacing,
  sizes,
  // Text
  fonts: {
    heading: `'Noto Sans JP', sans-serif`,
    body: `'Noto Sans JP', sans-serif`,
    serif: "serif",
  },
  fontSizes: {
    "2xs": "10px",
    xs: "12px",
    sm: "13px",
    md: "14px",
    lg: "16px",
    xl: "18px",
    "2xl": "23px",
    "3xl": "30px",
    "4xl": "32px",
  },
  lineHeights: {
    normal: "normal",
    none: "1",
    shorter: "1.25",
    short: "1.375",
    base: "1.5",
    tall: "1.625",
    taller: "2",
  },
  letterSpacings: {
    tighter: "-0.05em",
    tight: "-0.025em",
    normal: "0",
    wide: "0.025em",
    wider: "0.04em",
    widest: "0.1em",
  },
  fontWeights: {
    normal: 400,
    medium: 500,
    bold: 700,
  },
  radii: {
    m: "10px",
    "2m": "20px",
  },
  textStyles,
  layerStyles,
  colors,
  shadows: {
    // Checkboxはfocus-visibleが動かないので無効にする
    // アクセシビリティ考えたら有効にしたいけど一般的に不評なので...
    // ref: https://github.com/chakra-ui/chakra-ui/issues/2234
    outline: "none",
    tertiary: "2px 2px 0px #D14600",
    tertiary_hover: "2px 2px 0px #BB4300",
    tertiary_plain: "2px 2px 2px #D14600",
    tertiary_plain_hover: "2px 2px 2px #BB4300",
    textOnUIMain: "#F75E29 0 0 3px",
    btn: "0 4px 4px rgba(0,0,0,0.1)",
  },
  components,
  styles: {
    global: {
      body: {
        color: "text.black",
      },
    },
  },
}
