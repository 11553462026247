import { useBreakpointValue } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import { createCtx } from "../../lib/react/context"

export type DesignVariantContext = "base" | "pc"

const defaultValue = "base"

const [useCtx, Provider] = createCtx<DesignVariantContext>(
  "DesignVariantContext",
  defaultValue
)

/** 現在のbreakpointがデザインの定義上のbase,pcのどちらかを返します。 */
export const useDesignVariant = useCtx

export const DesignVariantProvider: React.FC<
  React.PropsWithChildren<{
    /** 強制的に特定のDesignVariantに固定します */
    forceVariant?: DesignVariantContext
  }>
> = (props) => {
  const v =
    useBreakpointValue<DesignVariantContext>({
      base: defaultValue,
      md: "pc",
    }) ?? defaultValue
  return <Provider value={props.forceVariant ?? v}>{props.children}</Provider>
}

/** 現在のDesign variantがpcであるかどうかの真偽値を返すヘルパーです */
export const usePCLayout = () => {
  const [isPC, setIsPC] = useState(false)
  const designVariant = useDesignVariant()

  useEffect(() => {
    setIsPC(designVariant === "pc")
  }, [designVariant])

  return isPC
}

/** マネイロで使用するSP/PCデザインそれぞれの値を渡すと、現在の画面幅に合わせた値を返します
 *
 * useBreakpointValueのdesignVariant版で引数はbase,pcを取ります
 * - e.g. `useResponsiveValue("base value", "pc value")
 */
export function useResponsiveValue<T = any>(base: T, pc: T): T {
  const isPC = usePCLayout()

  return isPC ? pc : base
}
