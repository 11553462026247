/** デバイスを跨いだセッションを維持するためのヘルパー */
import { PromiseProps } from "@~/common/lib/promise"
import { loadSourcePage, saveSourcePage } from "@~/common/lib/track"
import { compact } from "lib/loda"
import { captureAuthException } from "lib/sentry"
import {
  decode as base64decode,
  encode as base64encodeURI,
} from "universal-base64url"
import { getGAClientID } from "../track/ga"
import {
  getEmailForSignin,
  getNextPath,
  getRegistrationPref,
  RegistrationPref,
  setEmailForSignin,
  setFormDataFromKV,
  setNextPath,
  setRegistrationPref,
  storeFormDataToKV,
} from "./flow/flags"

/** RegistrationPrefに加えてURLに保存する項目
 *
 * - メール認証に施用します
 * - JSONにエンコードしてURLに埋め込むので、できるだけ短いキーと値にしたい
 */
export type StorageSnapshotInURL = RegistrationPref & {
  /** email */
  m?: string | null

  /** nextPath */
  n?: string | null

  /** firstLandingPath */
  f?: string

  /** Google アナリティクスのクライアント ID */
  c?: string

  /** 登録フォームの内容をマルチデバイスで引き継ぐためのKVのキー */
  k?: string
}

/** URL-safeにエンコード済みの登録時オプションを取得
 * - setNextPathを実行した後に実行すること
 * - email用
 */
export const loadSnapshotFromURLParam = async (encoded: string) => {
  // prefをデコードして、通常時と同じ状態になるようにLocalStorage/Memoryに読み込む
  try {
    const pref = encoded && decodePref(encoded)
    if (typeof pref === "object") {
      const { m, n, f, c: _c, e: _e, k, ...other } = pref
      const queue: unknown[] = []
      n && queue.push(setNextPath(n))
      m && queue.push(setEmailForSignin(m))
      f && queue.push(saveSourcePage({ url: f, overwrite: true }))
      k && queue.push(setFormDataFromKV(k))
      other && queue.push(setRegistrationPref(other))
      await Promise.all(queue)
    }
  } catch (e) {
    captureAuthException(e)
  }
}

/** URL-safeにエンコード済みの登録時オプションを取得
 * メール認証の場合メールアドレスも保存してある
 */
export const generateSnapshotForURL = async () => {
  return encodePref({
    ...(await getRegistrationPref()),
    ...(await PromiseProps({
      n: getNextPath(),
      m: getEmailForSignin(),
      f: loadSourcePage(),
      c: getGAClientID(),
      k: storeFormDataToKV(),
    })),
  })
}

const apply = (v: any, f: (input: any) => any) => f(v)
export const encodePref = (input: StorageSnapshotInURL): string =>
  [compact, JSON.stringify, base64encodeURI].reduce(apply, input) // 関数合成
export const decodePref = (encoded: string): StorageSnapshotInURL | undefined =>
  [base64decode, JSON.parse].reduce(apply, encoded) // 関数合成

// メール認証のオプション pref がクエリーに存在する場合は
// Google Analytics の clientId を取り出して返す
export const decodeGAClientID = (): string | undefined => {
  if (typeof window !== "undefined" && "location" in window) {
    const encodedPref = new URLSearchParams(window.location.search).get("pref")
    if (encodedPref === null) return

    try {
      const raw = base64decode(encodedPref)
      const pref: StorageSnapshotInURL | undefined = JSON.parse(raw)
      if (typeof pref === "object") {
        return pref.c
      }
    } catch {}
  }
}
