export const layerStyles = {
  base: {
    background: "white",
    boxShadow: "0px 1px 8px rgba(0, 0, 0, 0.04)",
    borderRadius: "10px",
  },
  rounded: {
    background: "white",
    borderRadius: "10px",
  },
  box: {
    background: "white",
    borderRadius: "5px",
  },
  passive: {
    background: "bg.gray2",
    borderRadius: "5px",
  },
  main: {
    borderRadius: "20px",
    border: "solid 2px",
    borderColor: "ui.main",
  },
  linkBox: {
    borderRadius: "10px",
    borderWidth: "1px",
    borderColor: "line.gray",
  },
  // オレンジ色の囲い
  notice: {
    background: "rgba(255, 159, 53, 0.15)", // ui.subのalpha 15%
    borderColor: "ui.sub",
    borderRadius: "10px",
    borderWidth: "1px",
  },
  tertiary_plain: {
    borderColor: "ui.main",
    borderWidth: "2px",
    borderRadius: "10px",
    background: "white",
  },
}
