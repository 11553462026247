import { BaseError } from "@~/common/lib/error"

export * from "@~/common/lib/error"

// ここにmain独自のエラーをBaseErrorを継承して定義する

export class ExternalServiceError extends BaseError {
  url?: string
  params?: unknown

  constructor(message: string, url?: string, params?: unknown) {
    super(message)
    this.url = url
    this.params = params
  }

  public toJSON() {
    return {
      message: this.message,
      url: this.url ?? null,
      params: this.params ?? null,
    }
  }
}
