import React, { useState } from "react"

type HeaderContextValue = {
  height: number
}

type HeaderContext = [
  HeaderContextValue,
  React.Dispatch<React.SetStateAction<HeaderContextValue>>
]

const _ctx = React.createContext<HeaderContext | undefined>(undefined)
const defaultContextValue: HeaderContextValue = { height: 78 }

export const useHeaderHeightValue = () =>
  React.useContext(_ctx)?.[0]?.height ?? defaultContextValue.height

export const useHeaderHeight = () => {
  const v = React.useContext(_ctx)
  if (!v) throw new Error("Use HeaderProvider")
  return v
}

/** ヘッダー(高さなど)を管理します */
export const HeaderManager: React.FC<React.PropsWithChildren<{}>> = (props) => {
  const [value, setValue] = useState(defaultContextValue)
  return <_ctx.Provider {...props} value={[value, setValue]} />
}
