import { ComponentStyleConfig } from "@chakra-ui/react"

// @ts-expect-error - theme typing not working, @chakra-ui/systemのバージョンが固定できないため
const Breadcrumb: ComponentStyleConfig = {
  variants: {
    video: {
      container: {
        bgColor: "bg.gray2",
      },
      item: {
        textStyle: "breadcrumb",
      },
      link: {
        _activeLink: {
          textColor: "text.main",
        },
      },
      // separator: <ChevronRightIcon w="1rem" color="text.main" />
    },
  },
  sizes: {
    md: {
      container: {
        p: 2,
        fontSize: { base: "11px", md: "12px" },
        lineHeight: { base: "20px", md: "17px" },
      },
    },
  },
}

export default Breadcrumb
