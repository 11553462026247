export const SmartnewsAdsPageView = (id?: string) => {
  SmartnewsAdsEvent(id, "PageView")
}

export const SmartnewsAdsEvent = (id?: string, event?: string) => {
  if (!id || !event) return

  if (process.env.NODE_ENV === "development" && typeof window === "object") {
    console.groupCollapsed("[SmartnewsAds] new event")
    console.log("id", id)
    console.log("event", event)
    console.groupEnd()
  } else if (
    window.SmartnewsAds?.p &&
    typeof window.SmartnewsAds.p === "function"
  ) {
    window.SmartnewsAds.p(id, event)
  }
}
