import { loadSourcePage } from "@~/common/lib/track"

/** 保存してあるランディングURLを取得する */
export const loadStoredSourceQuery = (
  raw = loadSourcePage()
): { [key: string]: string | undefined } => {
  if (!raw) return {}
  return {
    source_url: raw,
  }
}

export const parseURLParams = (
  rawURL: string
): Pick<URLSearchParams, "get"> => {
  let url: URL
  try {
    url = new URL(rawURL, "http://example.com")
  } catch (e) {
    return {
      get: (key: string) => null,
    }
  }
  return url.searchParams
}
