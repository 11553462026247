import { BaseError } from "./error"

export const httpError = <
  R extends { status: number; statusText: string; ok: boolean } = Response
>(
  r: Promise<R>
): Promise<R> =>
  r.then((r) => {
    if (!r.ok) {
      throw new HTTPError(r.status, r.statusText, r)
    }
    return r
  })

/** httpError適用済みのfetch */
export const httpFetch = (input: RequestInfo, init?: RequestInit) =>
  httpError(fetch(input, init))

export class HTTPError extends BaseError {
  public statusCode: number
  public statusText: string
  public response: any

  constructor(code: number, statusText: string, response: any) {
    super(statusText)
    this.name = "HTTPError"
    this.statusCode = code
    this.statusText = statusText
    this.response = response
  }
}

export const isHTTPError = (e?: any): e is HTTPError =>
  e && e instanceof HTTPError
