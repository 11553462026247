import { InitialAuthState } from "lib/auth/user"
import React from "react"
import { AuthStateProvider } from "../AuthContext"
import { AuthProviderConfig, dummyProviderConfig } from "../AuthProviderConfig"

export const AuthStateDummyProvider = ({
  disabled,
  ...props
}: React.PropsWithChildren<any>) => {
  return (
    <AuthProviderConfig value={dummyProviderConfig}>
      <AuthStateProvider value={InitialAuthState} {...props} />
    </AuthProviderConfig>
  )
}
