import * as Sentry from "@sentry/nextjs"
import { storeDel, storeGet, storeSet } from "@~/common/lib/store/helper"
import { compact } from "lib/loda"
import { captureAuthException } from "lib/sentry"
import { loadAdParams } from "lib/track"
import { getFormData, send } from "../../../pages/api/consulting/kv/_index"
import { EMAIL_LINK_EXPIRE } from "../../firebase/auth/providers/impl/email.const"
import {
  AUTH_PENDING_REDIRECT_KEY,
  EMAIL_FOR_SIGNIN_KEY,
  NEXT_PATH_KEY,
  REGISTRATION_PREF_KEY,
  REMEMBER_LAST_LOGIN_KEY,
} from "./keys"

/** 遷移後のパスを保存する */
export const setNextPath = async (path: string) => storeSet(NEXT_PATH_KEY, path)

/** 遷移後のパスを取得
 *
 * - process: よしなフラグ: 認証ページがNextPathになっていたら無視する、広告パラメーターを付与する
 */
export const getNextPath = async (
  process: boolean = false
): Promise<string | undefined> => {
  let ret = await storeGet(NEXT_PATH_KEY)
  if (process) {
    if (!(ret && ret.startsWith("/") && !ret.startsWith("/auth"))) {
      ret = undefined
    } else {
      ret = addAdParams(ret)
    }
  }
  return ret
}

export const clearNextPath = async () => await storeDel(NEXT_PATH_KEY)

/** 広告のパラメータが見つかればパスに追加する */
const addAdParams = (path: string) => {
  const params = loadAdParams()
  if (Object.keys(params).length === 0) return path

  try {
    const url = new URL(path, "http://example.com")

    Object.keys(params).forEach((k) => {
      if (url.searchParams.has(k)) return // 重複して付与しないように一応チェック

      const v = params[k]
      if (v) {
        url.searchParams.append(k, v)
      }
    })

    return url.pathname + url.search + url.hash
  } catch (e) {
    // do nothing
    captureAuthException(e)
  }

  return path
}

/** 登録前の設定
 * エンコードしたいのでできるだけコンパクトにしたい
 */
export type RegistrationPref = {
  /** emailOptOut, 0: false, 1: true */
  e?: number
}

/** 登録時のオプションをlocalStorageに保存する */
export const setRegistrationPref = async (
  options: Partial<RegistrationPref>
) => {
  let pref = await getRegistrationPref()
  pref = { ...pref, ...compact(options) }
  await storeSet(REGISTRATION_PREF_KEY, pref)
}

/** 登録時のオプションを取得する */
export const getRegistrationPref = async (): Promise<RegistrationPref> =>
  (await storeGet(REGISTRATION_PREF_KEY)) || {}

export const setEmailForSignin = async (email: string) =>
  storeSet(EMAIL_FOR_SIGNIN_KEY, email)
export const getEmailForSignin = async () =>
  await storeGet(EMAIL_FOR_SIGNIN_KEY)
export const clearEmailForSignin = async () =>
  await storeDel(EMAIL_FOR_SIGNIN_KEY)

// Last login

export type LastLoginInfo = {
  provider: string
  options?: JsonValue | null
}
/** 最終ログイン成功時の情報をlocalStorageに保存します */

export const setLastLoginSuccessful = (input?: LastLoginInfo | null) =>
  input
    ? storeSet(REMEMBER_LAST_LOGIN_KEY, input)
    : storeDel(REMEMBER_LAST_LOGIN_KEY)

/** 最終ログイン成功時の情報を取得します */

export const getLastLogin: () => Promise<LastLoginInfo | null> = async () =>
  (await storeGet(REMEMBER_LAST_LOGIN_KEY)) ?? null

// リダイレクト待ち情報

export type PendingRedirect = {
  redirect_path: string
}
/** リダイレクト待ち情報を保存する
 *
 * - プロバイダーによっては自前で移動するので不要だが、そうでない場合は遷移がおかしい場合に使用する
 */
export const setPendingRedirect = (input: PendingRedirect) =>
  storeSet(AUTH_PENDING_REDIRECT_KEY, input)

/** リダイレクト待ち情報をクリアします */
export const removePendingRedirect = () => storeDel(AUTH_PENDING_REDIRECT_KEY)

/** リダイレクト待ち情報を取得する
 * - この情報がある場合はコールバックURLに移動させる
 */
export const getPendingRedirect: () => Promise<PendingRedirect | null> =
  async () => (await storeGet(AUTH_PENDING_REDIRECT_KEY)) ?? null

/** リダイレクト待ち情報を取り出してクリアします */
export const popPendingRedirect = async () => {
  const v = await getPendingRedirect()
  await removePendingRedirect()
  return v
}

// KVに保存したキーを返す
export const storeFormDataToKV = async (): Promise<string | undefined> => {
  // 相談申し込みの途中で認証を挟むパターンの場合に作用する
  // マルチデバイスに対応するためにフォーム入力内容をKVに一時保存する
  const formData = await storeGet("form:consulting")
  if (!formData || !formData["register_method"]) {
    return undefined
  }

  const kvKey = crypto.randomUUID()
  await send(kvKey, formData, EMAIL_LINK_EXPIRE).catch((e) => {
    console.error(e)
    Sentry.captureException(e)
    // 保存に失敗したらマルチデバイス対応で失敗することになるが認証フローは止めない
    return undefined
  })
  return kvKey
}

export const setFormDataFromKV = async (kvKey: string) => {
  const formData = await getFormData(kvKey)

  await storeSet("form:consulting", formData)
}
