import { ChakraProvider } from "@chakra-ui/react"
import { ComponentProps } from "react"

// ダークテーマ対応しないため固定で light を返す
export const forceLightThemeStorageManager: ComponentProps<
  typeof ChakraProvider
>["colorModeManager"] = {
  get() {
    return "light"
  },
  set() {},
  type: "cookie",
}
