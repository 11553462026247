import memoryStorage from "@kvs/memorystorage/lib/localstorage-memory"
import { kvsStorage } from "@kvs/storage"
import { KVS } from "@kvs/types"
import memory from "memory-cache"

export type TStore = KVS<AppStorage>

/** Storeで使用するデータのスキーマ
 * 使用箇所で適宜interfaceに追加定義することで独自のキーを定義できます
 */
export interface AppStorage extends BaseSchema {}

type BaseSchema = {
  [index: string]: JsonValue
}

/** storeを取得します */
export const Store = async (key = "omp"): Promise<TStore> => {
  return getset(`store:${key}`, async () => {
    const version = 1

    console.debug("[store]: use storage")
    return await kvsStorage({
      storage: getBackend(),
      name: key,
      version,
    })
  })
}

const getset = async <T>(key: string, value: () => Promise<T>): Promise<T> => {
  return memory.get(key) ?? memory.put(key, await value())
}

const getBackend = (): Storage => {
  if (typeof window !== "undefined") {
    if (testLocalStorage("localStorage")) {
      return window.localStorage
    }
    if (testLocalStorage("sesseionStorage")) {
      return window.sessionStorage
    }
  }
  return memoryStorage
}

const testLocalStorage = (s: "localStorage" | "sesseionStorage") => {
  try {
    if (!(s in window && window[s])) {
      return false
    }
    const l = window[s]

    const key = "__test"
    l.setItem(key, "1")
    l.getItem(key) === "1"
    l.removeItem(key)
    return true
  } catch (e) {
    console.warn(`[store]: ${s} is not available, cause: ${e}`)
    return false
  }
}
