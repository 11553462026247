import Button from "./button"
import Checkbox from "./checkbox"
import Progress from "./progress"
import Radio from "./radio"
import Skeleton from "./skeleton"
import Tabs from "./tabs"
import Input from "./input"
import Breadcrumb from "./breadcrumb"
import Select from "./select"
import Modal from "./modal"

export const components = {
  Button,
  Checkbox,
  Progress,
  Radio,
  Skeleton,
  Tabs,
  Input,
  Breadcrumb,
  Select,
  Modal,
}
