import { ProviderKey } from "lib/auth/ProviderKey"
import { SessionAction } from "lib/auth/flow/SessionAction"
import { BaseError, Level, LeveledError } from "lib/error"

export type OIDCAuthErrorCode =
  | "oidc/has-other-provider" // 同じメアドで別のプロバイダがあるのでそちらを使って欲しい
  | "oidc/provider-failed" // プロバイダ側からエラーかキャンセル, 詳細は不明(細かく分けるなら`oidc/provider-cancel`などを足す)
  | "oidc/firebse-failed" // プロバイダにログインできたが、その後のユーザー作成などに失敗, やり直すしかない
  | "oidc/precondition" // 事前条件がおかしい, 実装ミスかもしれないし辺なURLを開いかもしれない
  | "oidc/browser_changed" // ブラウザが変わったのでログインできない
  | "oidc/error" // よくわからないエラー

export type OIDCAuthErrorCodeDetails = {
  "oidc/has-other-provider": { providers: string[] } // プロバイダーの配列
  "oidc/browser_changed": {
    action: SessionAction
    provider: ProviderKey
    next_path?: string
  }
  [key: string]: unknown
}

export class OIDCAuthError extends BaseError implements LeveledError {
  public code: OIDCAuthErrorCode
  public data: OIDCAuthErrorCodeDetails[typeof this.code]

  constructor(code: OIDCAuthErrorCode, message?: string, data?: any) {
    super(code)
    this.name = "OIDCAuthError"
    this.code = code
    this.data = data
    this.message = message ?? code
  }

  get level(): Level | undefined {
    if (
      this.code === "oidc/browser_changed" ||
      this.code === "oidc/has-other-provider"
    ) {
      return "warning"
    }
    return undefined
  }

  static withCode<C extends OIDCAuthErrorCode>(
    code: C,
    message?: string,
    data?: OIDCAuthErrorCodeDetails[C]
  ) {
    return new OIDCAuthError(code, message, data)
  }
}

export const isOIDCAuthError = (error: any): error is OIDCAuthError => {
  return error && error instanceof OIDCAuthError
}
