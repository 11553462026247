import * as Sentry from "@sentry/nextjs"
import type { Scope } from "@sentry/nextjs"
import { LeveledError } from "./error"
import { firebaseErrorLevel, isFirebaseError } from "./auth/error"

type Primitive = Parameters<Scope["setTag"]>[1]

export const captureAuthException = (
  e: any,
  tags: Record<string, Primitive> = {}
): void => {
  console.error(e)
  Sentry.withScope((scope) => {
    const level =
      (e as LeveledError).level ||
      (isFirebaseError(e) && firebaseErrorLevel(e)) ||
      undefined
    level && scope.setLevel(level)
    scope.setTags({
      section: "auth",
      ...tags,
    })
    Sentry.captureException(e)
  })
}
