import "@~/common/components/open-source"
import "focus-visible/dist/focus-visible"

import {
  CSSReset as ChakraCSSReset,
  ChakraProvider,
  PortalManager,
} from "@chakra-ui/react"
import { SpeedInsights } from "@vercel/speed-insights/next"
import { CommonMetaTag, MetaTag } from "@~/common/components"
import { HeaderManager } from "@~/common/components/layout/Header/hooks"
import { DesignVariantProvider } from "@~/common/components/system"
import { forceLightThemeStorageManager } from "@~/common/components/system/chakra/storageManager"
import { SourceTracker } from "@~/common/components/trackers"
import { MoneiroPageComponent } from "app-env"
import { ClientApolloProviderDynamic as ApolloProviderDynamic } from "components/apollo"
import { ClientApolloProviderDynamic as CustomerApolloProviderDynamic } from "components/apollo/customer"
import { AuthStateDummyProvider } from "components/auth/dummy"
import {
  AuthStateFirebaseLoginModalDynamic,
  AuthStateFirebaseProviderDynamic,
} from "components/auth/firebase"
import { theme as AppTheme } from "components/system/theme"
import { UserStateTracker } from "components/trackers/authenticated"
import { MainAppTracker } from "components/trackers/gtm"
import { AppProps } from "next/app"
import Script from "next/script"

export default function App({
  Component,
  pageProps,
  err,
}: AppProps & {
  // workaround for: https://github.com/vercel/next.js/issues/8592
  err?: any
}) {
  const page = Component as MoneiroPageComponent

  /** Use raw */
  const noAuth = page.noAuth
  const noTracker = !!page.noTracker
  const theme = page.customTheme ?? AppTheme
  const CSSReset = page.customReset ?? ChakraCSSReset

  const authType = noAuth ? "dummy" : "firebase"

  const AuthProvider = {
    firebase: AuthStateFirebaseProviderDynamic,
    dummy: AuthStateDummyProvider,
  }[authType]

  const ApolloProvider = {
    firebase: CustomerApolloProviderDynamic,
    dummy: ApolloProviderDynamic,
  }[authType]

  // ページ別のレイアウトが定義されていればそれを使う
  const getLayout = page.getLayout ?? ((page) => page)

  return (
    <ChakraProvider
      // TODO: layoutに移動する
      resetCSS={false}
      theme={theme}
      colorModeManager={forceLightThemeStorageManager}
    >
      <CSSReset />
      <CommonMetaTag />
      <MetaTag
        title={defaultTitle}
        description={defaultDescription}
        ogImage={defaultOGImage}
        canonical={null}
      />
      <AuthProvider>
        <ApolloProvider>
          <DesignVariantProvider>
            <HeaderManager>
              <PortalManager zIndex={1}>
                {getLayout(<Component {...pageProps} err={err} />)}
              </PortalManager>
            </HeaderManager>
            {!noAuth && <UserStateTracker />}
            {!noAuth && (
              <AuthStateFirebaseLoginModalDynamic disabled={!!noAuth} />
            )}
          </DesignVariantProvider>
        </ApolloProvider>
      </AuthProvider>
      <SpeedInsights sampleRate={0.1} />
      {!noTracker && <MainAppTracker />}
      {!noTracker && <SourceTracker />}
      {typeof window === "object" &&
        window.location.search.indexOf("__debug=1") !== -1 && (
          <Script
            strategy="afterInteractive"
            src="https://cdnjs.cloudflare.com/ajax/libs/vConsole/3.9.5/vconsole.min.js"
            onLoad={() => {
              if (
                typeof window === "object" &&
                typeof window["VConsole"] !== "undefined"
              ) {
                const vconsole = new window["VConsole"]()
                vconsole.setSwitchPosition(window.innerWidth, 0)
              }
            }}
          />
        )}
    </ChakraProvider>
  )
}

const defaultTitle = "マネイロ｜はたらく世代のお金の診断・相談サービス"
const defaultDescription =
  "マネイロは、30-40代を中心とするはたらく世代のお金の診断・相談サービスです。あなたが将来いくら必要なのか、そのためにどんな投資がオススメなのかを、スマホで簡単に診断。困ったときはお金のプロに無料で相談できます。"
const defaultOGImage = "https://moneiro.jp/og.png"
