import { parseCookies, setCookie } from "nookies"

const __key = "_moneiro_first_landing"
const __key_hslp = "hslp_source_url"

/** 訪れたページを記憶します
 *
 * @param overwrite trueならcookieの値を上書きします。デフォルトはfalse
 *
 */
export const saveSourcePage = ({
  url = location.href,
  overwrite = false,
}: { url?: string; overwrite?: boolean } = {}) => {
  let { [__key]: v = url } = parseCookies()

  if (overwrite) v = url

  v = parseCookies()[__key_hslp] ?? processRewrites(v, url)

  setCookie(null, __key, v, {
    secure: process.env.NODE_ENV === "production",
    domain: cookieDomain(),
    path: "/",
    sameSite: "lax",
    expires: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000), // 30日
  })
}

/** URLを特定の条件でcurrentからurlに差し替えます
 *
 * - utm_campaign パラメータが含まれている場合
 * - soruce_url パラメータが含まれている場合
 **/
const processRewrites = (current: string, url: string) => {
  try {
    const u = new URL(url)
    if (u.searchParams.has("utm_campaign")) {
      return url
    }
    const source_url_param = u.searchParams.get("source_url")
    if (source_url_param) {
      return source_url_param
    }
    return current
  } catch {
    return current
  }
}

const cookieDomain = (): string | undefined => {
  if (process.env.NODE_ENV === "production") {
    return ".moneiro.jp"
  } else if (process.env.NODE_ENV === "test") {
    return ".example.com"
  }
  return undefined
}

/** 訪れたページを呼び出します */
export const loadSourcePage = (): string | undefined => {
  return parseCookies()[__key]
}

/** 訪れたページを呼び出します。保存されていない場合は `location.href`の値を返します */
export const loadSourcePageWithDefault = () => loadSourcePage() ?? location.href

if (process.env.NODE_ENV === "development") {
  if (typeof window === "object") {
    window["loadSourcePage"] = loadSourcePage
  }
}
