import { createContext, Provider, useContext } from "react"

/** Provider内使用チェックの入った`React.createContext` */
export function createCtx<T>(
  name: string,
  defaultValue: T
): [() => T, Provider<T>]
export function createCtx<T>(
  /** コンテキスト名(ContextのdisplayNameに使用されます) */
  name: string,
  /** デフォルト値, Provider外で使用された場合のデフォルト値 */
  defaultValue?: undefined
): [() => T | undefined, Provider<T>]
export function createCtx<T>(name: string, defaultValue?: T) {
  const ctx = createContext<T | sentinel>(sentinel)
  if (process.env.NODE_ENV === "development") ctx.displayName = name
  function useCtx() {
    const v = useContext(ctx)
    if (v === sentinel && process.env.NODE_ENV === "development")
      throw new Error(`${name} must be used inside Provider`)
    return v === sentinel ? defaultValue : v
  }
  return [useCtx, ctx.Provider] as const
}

// Provider外使用時のデフォルト値
// undefinedを一応使用できるようにundefinedでもTでもない値を使用します
const sentinel = "__default"
type sentinel = typeof sentinel
