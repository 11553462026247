import { StyleObjectOrFn } from "@chakra-ui/styled-system"
import generated from "./textStyles-figma.gen"

/** (最新のchakra-uiからコピーしてきた型) */
interface SystemStyleObjectRecord {
  [key: string]: StyleObjectOrFn
}

/** テキストスタイル
 *
 * - textStyle=""で使用できます。
 * - NOTE: GPデザイン時代のスタイルとマネイロデザイナーチーム発足後のスタイルが混ざっている
 *   - PCでもSPのフォントを使用することがある
 *      - textStyle={['h2', 'h3']} みたいなことができないので各組み合わせを作る必要があったが、最新のchakra-uiでできるようになりました
 *   - fontSize,font,letterSpacing,lineHeightは基本セットなので別のオブジェクトにまとめてからtextStyleに入れたい
 * - TODO: FigmaのTextStyleと同期する
 */
export const textStyles: SystemStyleObjectRecord = {
  ...generated,
  h1: {
    fontSize: { base: "23px", md: "32px" },
    lineHeight: { base: "30px", md: "50px" },
    letterSpacing: "0.08em",
    fontWeight: "normal",
  },
  h2: {
    fontSize: { base: "18px", md: "30px" },
    lineHeight: { base: "28px", md: "42px" },
    letterSpacing: "0.08em",
    fontWeight: "normal",
  },
  h3: {
    fontSize: { base: "16px", md: "22px" },
    lineHeight: { base: "24px", md: "34px" },
    letterSpacing: "0.04em",
    // figmaだとmd: "normal"になってるっぽい。Noto Sansに移行した時に変わったのかも。要確認
    fontWeight: { base: "bold", md: "medium" },
  },
  h4: {
    fontSize: { base: "14px", md: "18px" },
    lineHeight: { base: "22px", md: "24px" },
    letterSpacing: { base: "0.04em", md: "0.08em" },
    fontWeight: "bold",
  },
  body: {
    fontSize: { base: "14px", md: "16px" },
    lineHeight: { base: "22px", md: "30px" },
    letterSpacing: "0.05em",
    fontWeight: "normal",
  },
  // SP版
  h2SP: {
    fontSize: "18px",
    lineHeight: "28px",
    letterSpacing: "0.08em",
    fontWeight: "normal",
  },
  h3SP: {
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.04em",
    fontWeight: "bold",
  },
  h4SP: {
    fontSize: "14px",
    lineHeight: "22px",
    letterSpacing: "0.04em",
    fontWeight: "bold",
  },
  // bodyのSPを常に使う
  bodySP: {
    fontSize: "14px",
    lineHeight: "22px",
    letterSpacing: "0.05em",
    fontWeight: "normal",
  },
  bodyBolder: {
    fontSize: { base: "12px", md: "16px" },
    lineHeight: { base: "22px", md: "30px" },
    letterSpacing: "0.05em",
    fontWeight: "bold",
  },
  bodyBold: {
    fontSize: { base: "12px", md: "14px" },
    lineHeight: { base: "22px", md: "30px" },
    letterSpacing: "0.05em",
    fontWeight: "bold",
  },
  bodyBold12: {
    fontSize: "12px",
    lineHeight: "22px",
    letterSpacing: "0.05em",
    fontWeight: "bold",
  },
  bodyBold14: {
    fontSize: { base: "14px", md: "16px" },
    lineHeight: { base: "22px", md: "28px" },
    letterSpacing: "0.05em",
    fontWeight: "bold",
  },
  bodyBoldPC: {
    fontSize: "16px",
    lineHeight: "30px",
    letterSpacing: "0.05em",
    fontWeight: "bold",
  },
  bodyBoldSP: {
    fontSize: "14px",
    lineHeight: "22px",
    letterSpacing: "0.05em",
    fontWeight: "bold",
  },
  button: {
    fontSize: "13px",
    lineHeight: "20px",
    letterSpacing: "0.05em",
    fontWeight: "normal",
  },
  tag: {
    fontSize: "13px",
    lineHeight: "22px",
    letterSpacing: "0.05em",
    fontWeight: "bold",
  },
  placeholder: { fontSize: "16px", lineHeight: "22px" },
  inherit: {
    fontSize: "inherit",
    lineHeight: "inherit",
    letterSpacing: "inherit",
    fontWeight: "inherit",
  },
  // これが基本なのでデフォルトをいじっても良いかも
  link: {
    color: "text.main",
    textDecor: "underline",
    _hover: {
      textDecor: "none",
    },
  },
  linkBold: {
    color: "text.main",
    fontWeight: "medium",
    textDecor: "underline",
    _hover: {
      textDecor: "none",
    },
  },
  bodySPLink: {
    fontSize: "14px",
    lineHeight: "22px",
    letterSpacing: "0.05em",
    fontWeight: "bold",

    color: "black",
    textDecor: "underline",
    _hover: {
      textDecor: "none",
    },
  },
  headerLink: {
    color: "text.black",
    fontWeight: "bold",
    fontSize: "md",
    textDecor: "none",
    _hover: {
      textDecor: "none",
      color: "text.main",
      background: "inherit",
    },
    _disabled: {
      opacity: 0.4,
      _hover: {
        color: "text.black",
        cursor: "initial",
      },
    },
  },
  note: {
    fontSize: { base: "12px", md: "13px" },
    lineHeight: "20px",
    letterSpacing: "0.05em",
  },
  notexs: {
    fontSize: "9px",
    letterSpacing: "0.065em",
  },
  breadcrumb: {
    fontSize: { base: "11px", md: "12px" },
    lineHeight: { base: "20px", md: "17px" },
  },
  widgetHeader: {
    fontSize: { base: "18px", md: "22px" },
    lineHeight: { base: "28px", md: "34px" },
    letterSpacing: "0.08em",
    fontWeight: "bold",
  },
  widgetHeaderSub: {
    fontSize: { base: "16px", md: "18px" },
    lineHeight: { base: "24px", md: "24px" },
    letterSpacing: { base: "0.04em", md: "0.08em" },
    fontWeight: "bold",
  },
  widgetTitle: {
    fontSize: { base: "16px", md: "18px" },
    lineHeight: { base: "23px", md: "28px" },
    fontWeight: "bold",
  },

  /** FigmaのText Stylesに登録されているもの
   *
   *  ex) Figma: 16-reg  -> Chakra UI: f16
   *      Figma: 16-bold -> Chakra UI: f16Bold
   */
  f30Bold: {
    fontSize: "30px",
    lineHeight: "42px",
    letterSpacing: "0.08em",
    fontWeight: 700,
  },
  f25: {
    fontSize: "25px",
    lineHeight: "28px",
    letterSpacing: "0.08em",
  },
  f24Bold: {
    fontSize: "24px",
    lineHeight: "32px",
    letterSpacing: "0.065em",
    fontWeight: 700,
  },
  f20: {
    fontSize: "20px",
    lineHeight: "28px",
    letterSpacing: "0.065em",
  },
  f20Bold: {
    fontSize: "20px",
    lineHeight: "28px",
    letterSpacing: "0.065em",
    fontWeight: 700,
  },
  f18Bold: {
    fontSize: "18px",
    lineHeight: "28px",
    letterSpacing: "0.08em",
    fontWeight: 700,
  },
  f18: {
    fontSize: "18px",
    lineHeight: "28px",
    letterSpacing: "0.08em",
  },
  f16: {
    fontSize: "16px",
    lineHeight: "30px",
    letterSpacing: "0.05em",
  },
  f16Bold: {
    fontSize: "16px",
    lineHeight: "30px",
    letterSpacing: "0.05em",
    fontWeight: 700,
  },
  f14: {
    // TODO: こちらにfontWeight: 400を入れたい
    fontSize: "14px",
    lineHeight: "22px",
    letterSpacing: "0.05em",
  },
  f14Bold: {
    fontSize: "14px",
    lineHeight: "22px",
    letterSpacing: "0.05em",
    fontWeight: 700,
  },
  f13: {
    fontSize: "13px",
    lineHeight: "22px",
    letterSpacing: "0.065em",
  },
  f13Bold: {
    fontSize: "13px",
    lineHeight: "22px",
    fontWeight: 700,
  },
  f12: {
    fontSize: "12px",
    lineHeight: "18px",
  },
  f12Bold: {
    fontSize: "12px",
    lineHeight: "22px",
    fontWeight: 700,
  },
  f11Bold: {
    fontSize: "11px",
    lineHeight: "16px",
    fontWeight: 700,
  },
  f11: {
    fontSize: "11px",
    lineHeight: "16px",
  },
  f10: {
    fontSize: "10px",
    lineHeight: "22px",
    letterSpacing: "0.05em",
  },
  /** カスタムしたHeadingコンポーネントでtextStyleを指定すると上書きされないためデフォルトとして指定するtextStyle */
  _headings: {
    fontFamily: "heading",
    fontWeight: "bold",
  },

  lifeplanBody: {
    fontSize: "18px",
    lineHeight: "26px",
    letterSpacing: "0.04em",
  },

  // 脚注へのリンク
  footnoteref: {
    position: "relative",
    fontSize: "0.8em",
    top: "-1.25em",
    marginRight: "-1em",
    letterSpacing: 0,
    lineHeight: "0.5em",
  },
}
