import { transparentize } from "@chakra-ui/theme-tools"
import {
  ComponentStyleConfig,
  ComponentSingleStyleConfig,
} from "@chakra-ui/react"

const baseStyle: ComponentSingleStyleConfig["baseStyle"] = (
  props: Record<string, any>
) => {
  if (props.colorScheme !== "form") return { control: {} }
  const bg = transparentize("ui.main", 0.2)
  return {
    control: {
      borderRadius: "full",
      borderColor: "ui.gray2",
      _checked: {
        bg: "bg.sub",
        borderColor: "transparent",
        color: "ui.main",
        _before: {
          w: "calc(50% + 2px)",
          h: "calc(50% + 2px)",
        },
      },
    },
  }
}
const defaultProps = {
  colorScheme: "form",
}

export default { baseStyle, defaultProps } as ComponentStyleConfig
