import { TitleSuffixes } from "../lib/constants"

export const getPageTitle = (
  title: string,
  titleSuffix: TitleSuffixOption = false
) => {
  if (titleSuffix === false) {
    return title
  } else {
    return title + (TitleSuffixes[titleSuffix] ?? titleSuffix)
  }
}

export type TitleSuffixOption = string | false
