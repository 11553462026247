import { withHubSpot } from "./HubSpot"
import { AppGTMDatalayerPush } from "./AppGTMDatalayer"
import { compact } from "../loda"

/** GTMにイベントを送信します
 *
 * 基本的にはGAにそのままイベントとして流しています。
 */
export const AppGTMEvent = (
  category: string,
  action: string,
  label?: string
) => {
  AppGTMDatalayerPush({
    event: "event",
    category: category,
    action: action,
    label: label,
  })
}

/** GTMにユーザーIDを設定します
 *
 * - GA上でUserIDが設定されます。
 */
export const AppGTMSetUserID = (uid?: string | null, email?: string | null) =>
  (uid || email) &&
  AppGTMDatalayerPush({
    event: "auth",
    uid: uid,
    email: email,
  })

/** HubSpotにIdentityを設定します */
export const AppHubSpotIdentity = (
  email?: string | null,
  id?: string | null
) => {
  // idを含めるだけではコンタクトは作成されないのでemailの存在確認
  // @see https://developers.hubspot.jp/docs/api/events/tracking-code#identify-a-visitor
  if (email === null || email === undefined) {
    return
  }

  withHubSpot((hs) =>
    hs([
      "identify",
      compact({
        email,
        id: id ?? undefined,
      }),
    ])
  )
}
