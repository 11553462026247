import React, { useContext } from "react"

/** AuthProviderが実装する現在のプロバイダー情報を提供するContext */
export type AuthProviderConfig = {
  provider: string
  sessionStartPath?: string
}

export const dummyProviderConfig = { provider: "" }

const ctx = React.createContext<AuthProviderConfig>(dummyProviderConfig)

export const useAuthProviderConfig = () => useContext(ctx)

export const AuthProviderConfig = ctx.Provider
